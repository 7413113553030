import React from "react";
import { Input, InputGroup, InputLeftAddon, InputLeftElement } from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";

export default function GeneralInputField({ icon,onIconClick, onChange, placeholder, inputType, value, height, width, ...rest }) {
  return (
    <InputGroup width={width} height={height} {...rest}>
      <InputLeftElement cursor='pointer' onClick={onIconClick} height="100%" >
        {icon}
      </InputLeftElement>
      <Input
        errorBorderColor="red.300"
        focusBorderColor="grey"
        borderColor="grey"
        _hover={{borderColor:'grey'}}
        placeholder={placeholder}
        color="white"
        _placeholder={{ opacity: 0.5, color: 'whitesmoke' }}
        type={inputType}
        value={value}
        onChange={onChange}
        width="100%"
        height="100%"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        backgroundColor="rgb(26, 24, 24)"
        boxShadow="0px 2px 5px grey"
        borderRadius='30px'
        {...rest}
      />
    </InputGroup>
  );
}
