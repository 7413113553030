// dateTimeFormatter.js
export const formatDateTime = (incomingDateTime) => {
    const date = new Date(incomingDateTime);
  
    const formatDate = (date) => {
      const options = { month: 'long', day: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options);
    };
  
    const formatTime = (date) => {
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      let timeString = date.toLocaleTimeString('en-GB', options);
      // Convert 'am' or 'pm' to uppercase 'AM' or 'PM'
      timeString = timeString.replace('am', 'AM').replace('pm', 'PM');
      return timeString;
    };
  
    const formattedDate = formatDate(date);
    const formattedTime = formatTime(date);
  
    return `${formattedDate} ${formattedTime}`;
  };
  