import { Flex, Center, IconButton, Text, Spacer, Box, Heading, VStack, HStack, Divider, Image, Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { MdMessage } from "react-icons/md";

// user Imports
import Enclave from "./Enclave";
import CreatePost from "./CreatePost";
import { colors } from "../../globalStyles/colors";
import ClusterNav from "./ClusterNav";
import GeneralInputField from "../../Components/GeneralInputField";
import Entity from "../connections/friends/Entity";
import MenuButton from "../../Components/MenuButton";
import CreateCluster from "./CreateCluster";

export default function ClusterBar({userDetails, updateShowPostComponent, clusterName, updateClusterChatScreenData}){
    const handleTextPost = () => {
        updateShowPostComponent(true)
    }
    const {isOpen, onOpen, onClose} = useDisclosure()
    return <>
        <Flex bgColor={colors.bgDark} height='100%' width='100%' flexDirection='column' borderWidth='0px' borderColor={colors.primary} mt='15px' >
        <HStack justifyContent='space-between' ml='5px' mr='10px' mt={2}>
          
          
          <Image src='../../logo-2-bg-remove.png' maxW='40px' />
          <VStack spacing={-1}>
            <Heading fontSize={25} color='white'>{clusterName}</Heading>
            <HStack spacing={1}><Text fontSize={16} color={colors.primary_dim} fontStyle='italic' >cr/: </Text> <Text fontSize={14} color={colors.primary_dim}>cluster</Text></HStack>

          </VStack>
         
          <ClusterNav userDetails={userDetails} clusterName={clusterName} updateClusterChatScreenData={updateClusterChatScreenData}/>
          

            
       </HStack>    
        
            
            <Box mt={10}/>
            
            <Enclave title='Enclaves' clusterName={clusterName} userDetails={userDetails} />
            <Center>
              <Button borderRadius='20px' width='250px' height='50px' fontSize='20px' color='whitesmoke' _hover={{bgColor:colors.primary}} bgColor='grey' onClick={onOpen}>Create an Enclave</Button>
            </Center>
            <CreateCluster
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                clusterName={clusterName} userDetails={userDetails} create='E'
              />
            <Box mb='10px'></Box>
            <Enclave title="Members" userDetails={userDetails}  clusterName={clusterName} />
            <Box mb='30px'></Box>
            <Center >
              <Button borderRadius='20px' width='250px' height='50px' fontSize='20px' color='whitesmoke' _hover={{bgColor:colors.primary}} bgColor={colors.primary_dim} onClick={() => handleTextPost()}>Post</Button>
            </Center>
            {/* <CreatePost updateShowPostComponent={updateShowPostComponent}/> */}
            
            
        </Flex>
    </>
}