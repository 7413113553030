import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChakraProvider, Box, Heading, Text, Divider, Button } from "@chakra-ui/react";
import TermsOfUse from "./TermsOfUse";

function AboutPage() {
  const navigate = useNavigate()
  const userDetails = useLocation().state?.userDetails

  return (
    <ChakraProvider>
      <Box bg="black" color="white" minHeight="100vh" p={8}>
        <Heading as="h1" fontSize="3xl" mb={6}>
          About Cluster
        </Heading>

        <Section heading="Version">
          <Text>
            Beta 1.0.0 &nbsp; Click <Button variant="link" onClick={() => navigate('/upcoming-version')}>here</Button> to view upcoming features.
          </Text>
        </Section>

        <Section heading="Vision">
          <Text>
            Empower users to connect effortlessly, express themselves freely, and build vibrant communities through clusters and enclaves.
          </Text>
        </Section>

        <Section heading="Message from Developer">
  <Text mb={4}>
    Welcome to Cluster! As the developers behind this platform, we're thrilled to introduce you to a new era of communication and collaboration.
  </Text>
  <Text mb={4}>
    Our mission in creating Cluster was to empower individuals like you to engage in meaningful interactions, share your passions, and forge connections that transcend boundaries.
  </Text>
  <Text mb={4}>
    We've crafted this platform with dedication and innovation to provide you with a seamless experience that fosters community and facilitates collaboration.
  </Text>
  <Text mb={4}>
    We invite you to embark on this journey with us and explore the endless possibilities that Cluster has to offer.
  </Text>
  <Text mb={4}>
    Your feedback is invaluable to us as we strive to continuously improve and refine our platform. Please take a moment to share your thoughts and suggestions with us. Your input will not only help us enhance the user experience but also shape the future direction of Cluster.
  </Text>
  <Text mb={4}>
    Click <Button variant='link' onClick={() => navigate('/feedback', {state : {userDetails : userDetails}})}>here</Button> to provide your feedback.
  </Text>
</Section>


        <Section heading="Features">
          <SectionSubheading heading="User-Specific Features">
            <Text>
              <ol>
                <li>User can connect with each other by simply making connection requests.</li>
                <li>Can access their public profiles, username, upload profile image, cover image, bio, posts and much more.</li>
                <li>User can create posts, upload media and text content.</li>
                <li>User can view, comment, like and share posts from different users and public clusters.</li>
                <li>A user can interact with cluster and enclave in various different ways.</li>
              </ol>
            </Text>
          </SectionSubheading>

          <SectionSubheading heading="Cluster-Specific Features">
            <Text>
              <ol>
                <li>A user can create, follow or request membership to a cluster.</li>
                <li>A cluster is a group with its accessibility to create public/private posts and have a group communication within its members.</li>
                <li>Cluster allows its members interact in a group and share common ideas/goals together.</li>
                <li>Cluster can be created for a particular topic or subtopic like Web Development, Graphic designing, etc.</li>
                <li>Members of cluster can view and accept membership requests, upload media, communicate and access cluster profiles.</li>
                <li>Within the cluster, member can also create enclaves which provides an unique functionality to the cluster.</li>
              </ol>
            </Text>
          </SectionSubheading>

          <SectionSubheading heading="Enclave-Specific Features">
            <Text>
              <ol>
                <li>An enclave is a sub group within the cluster with its unique properties.</li>
                <li>A cluster with category on Web Development can create enclave related to sub-topics like Fullstack, FrontEnd or Backend or any other subtopic.</li>
                <li>The members of cluster can join the enclaves without any approval.</li>
                <li>The members of enclave also has access to media upload and group communication within the Enclave.</li>
                <li>The enclave allows cluster and its members to have separate and unique discussions on their subdomains.</li>
              </ol>
            </Text>
          </SectionSubheading>
        </Section>
        <Section heading="Security">
          <Text>
            At Cluster, we prioritize the security and privacy of your data. Here's how we ensure your information remains safe:
          </Text>
          <ul>
            <li><strong>End-to-End Encryption:</strong> All messages sent through the Cluster application, whether person-to-person or within a group/cluster, are encrypted using 256-bit encryption. This means that your communications are protected from unauthorized access, ensuring that only you and the intended recipients can decrypt and view the contents of your messages.</li>
            <li><strong>JWT Token and Google's Secure Authentication:</strong> We employ industry-standard security protocols, including JWT tokens and Google's secure authentication, to verify and authenticate users. These measures help safeguard against unauthorized access to your account and ensure that only authorized individuals can access the application.</li>
            <li><strong>No Message Reading:</strong> It's important to note that we respect your privacy. We do not read or access your messages. Our end-to-end encryption ensures that your communications remain confidential, and we do not have the ability to decrypt or view your messages.</li>
          </ul>
          <Text>Click here to read <Button variant='link' onClick={() => navigate('/termsOfuse')}>Terms of use</Button></Text>
        </Section>
        

        {/* <Section heading="Features" id="upcoming">

        </Section> */}
      </Box>
    </ChakraProvider>
  );
}

function Section({ heading, children }) {
  return (
    <Box mb={6}>
      <Heading as="h2" fontSize="2xl" mb={4} mt={6}>
        {heading}
      </Heading>
      {children}
      <Divider borderColor="white" />
    </Box>
  );
}

function SectionSubheading({ heading, children }) {
  return (
    <Box mb={6}>
      <Heading as="h3" fontSize="xl" mb={4}>
        {heading}
      </Heading>
      {children}
    </Box>
  );
}

export default AboutPage;
