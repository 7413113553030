import React, { useRef, useState } from 'react';
import { Box, Image, useToast, Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import axios from 'axios';

const ProfileContainer = ({ userDetails, cluster, type, profile, attribute }) => {
  const default_profile_img_path = '../../../default-user.jpg';
  const default_bg_img_path = '../../../bg.png';
  console.log(profile.coverImageUrl)
  const [newCoverImage, setNewCoverImage] = useState(type === 'P' ? (profile.coverImageUrl || default_bg_img_path): (type === 'C' ? (profile?.coverImageUrl ||  default_bg_img_path ): null));
  const [newProfileImage, setNewProfileImage] = useState(type === 'P' ? (profile.profileImageUrl  || default_profile_img_path ): (type === 'C' ? (profile?.profileImageUrl || default_profile_img_path) : null));
  const coverInputRef = useRef(null);
  const profileInputRef = useRef(null);
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);

  const handleProfileImageUpload = async (imageData, file) => {
    if (!file) {
      toast({
        title: "Couldn't upload Image",
        description: "Try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        colorScheme: 'red',
      });
      return;
    }
    const awsurl = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/upload/global/get/presignedurl`, { params: { metaData: file, userName: userDetails?.userName } }).then(
      res => {
        console.log(res);
        return res.data;
      }
    ).catch(
      err => {
        console.log(err);
        toast({
          title: "Couldn't upload image",
          description: "Server error",
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'red',
        });
      }
    );
    console.log("awsurl " + awsurl);
    if (awsurl) {
      const isupload = handleUpload(awsurl.preSignedUrl, file);

      if (isupload) {
        if (type === 'P') {
          const updated = await updateUserDetails(awsurl.fileName, file, 'profileImage');

          if (updated) {
            toast({
              title: "Yay!",
              description: "Profile Updated",
              status: "success",
              duration: 5000,
              isClosable: true,
              variant: "solid",
              colorScheme: 'yellow',
            });
            setNewProfileImage(imageData);
          }
        } else if (type === 'C') {
          const updated = await updateClusterDetails(awsurl.fileName, file, 'profileImage');

          if (updated) {
            toast({
              title: "Yay!",
              description: "Profile Updated",
              status: "success",
              duration: 5000,
              isClosable: true,
              variant: "solid",
              colorScheme: 'yellow',
            });
            setNewProfileImage(imageData);
          }
        }
      }
    }
  };

  const updateUserDetails = async (fileName, file, updateType) => {
    const data = {
      fileName: fileName,
      metaData: file,
      userDetails: userDetails
    };

    console.log(data);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/user/update/${updateType}`, data).then(
        res => {
          console.log(res);
          return res;
        }).catch(err => {
          console.log(err);
        });
      if (response.status === 201)
        return true;

    } catch (e) {
      return false;
    }
  };

  const updateClusterDetails = async (fileName, file, updateType) => {
    const data = {
      fileName: fileName,
      metaData: file,
      cluster: cluster
    };

    console.log(data);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/update/${updateType}`, data).then(
        res => {
          console.log(res);
          return res;
        }).catch(err => {
          console.log(err);
        });
      if (response.status === 201)
        return true;

    } catch (e) {
      return false;
    }
  };

  const handleUpload = async (preSignedUrl, file) => {
    if (file) {
      try {
        const response = await axios.put(preSignedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        console.log('Image uploaded successfully!', response);
        return true;
      } catch (error) {
        console.error('Error uploading image:', error.message);
        toast({
          title: "Couldn't upload Image",
          description: "Try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'red',
        });
        return false;
      }
    }
  };

  const handleCoverImageUpload = async (imageData, file) => {
    if (!file) {
      toast({
        title: "Couldn't upload Image",
        description: "Try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        colorScheme: 'red',
      });
      return;
    }
    const awsurl = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/upload/global/get/presignedurl`, { params: { metaData: file, userName: userDetails?.userName } }).then(
      res => {
        console.log(res);
        return res.data;
      }
    ).catch(
      err => {
        console.log(err);
        toast({
          title: "Couldn't upload image",
          description: "Server error",
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'red',
        });
      }
    );
    console.log("awsurl " + awsurl);
    if (awsurl) {
      const isupload = handleUpload(awsurl.preSignedUrl, file);

      if (isupload) {
        if (type === 'P') {
          const updated = await updateUserDetails(awsurl.fileName, file, 'coverImage');

          if (updated) {
            toast({
              title: "Yay!",
              description: "Profile Updated",
              status: "success",
              duration: 5000,
              isClosable: true,
              variant: "solid",
              colorScheme: 'yellow',
            });
            setNewCoverImage(imageData);
          }
        } else if (type === 'C') {
          const updated = await updateClusterDetails(awsurl.fileName, file, 'coverImage');

          if (updated) {
            toast({
              title: "Yay!",
              description: "Profile Updated",
              status: "success",
              duration: 5000,
              isClosable: true,
              variant: "solid",
              colorScheme: 'yellow',
            });
            setNewCoverImage(imageData);
          }
        }
      }
    }
  };

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        handleCoverImageUpload(reader.result, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        handleProfileImageUpload(reader.result, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCoverImageClick = () => {
    if(attribute != 'D'){
      coverInputRef.current.click();
      return;
    }
      setClickedImage(newCoverImage);
      setIsModalOpen(true);
  };

  const handleProfileImageClick = () => {
    if(attribute != 'D'){
      profileInputRef.current.click();
      return;
    }
      setClickedImage(newProfileImage);
      setIsModalOpen(true);
  };

  const handleImageClick = (image) => {
    setClickedImage(image);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClickedImage(null);
  };

  return (
    <>
      <Box position="relative" width="100%" height="100%" overflow="visible">
        <Image
          src={newCoverImage}
          alt='Cover Image'
          width="100%"
          height={attribute === 'D' ? '180px' : "100%"}
          objectFit="cover"
          opacity="0.5"
          cursor="pointer"
          onClick={handleCoverImageClick}
          borderRadius={attribute === 'D' ? '5px' :'20px' }
          boxShadow="0px 10px 20px rgba(48, 48, 47), 0px 5px 10px rgba(0, 0, 0, 0.22)"
          transform={attribute === 'D' ? null :"scale(1) translateY(-10px)"}
          transition="all 0.3s ease-in-out"
        />
        {attribute !== 'D' && <input
          type="file"
          id="coverInput"
          ref={coverInputRef}
          style={{ display: 'none' }}
          onChange={handleCoverImageChange}
        />}
        <Image
          src={newProfileImage}
          alt='Profile Image'
          width="150px"
          height="150px"
          borderRadius="50%"
          position="absolute"
          top="85%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1"
          cursor="pointer"
          onClick={handleProfileImageClick}
        />
        {attribute !== 'D' && <input
          type="file"
          id="profileInput"
          ref={profileInputRef}
          style={{ display: 'none' }}
          onChange={handleProfileImageChange}
        />}
      </Box>
      {clickedImage && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
           <ModalOverlay bg="transparent" />
          <ModalContent bg="transparent" boxShadow="none">
            <ModalBody display="flex" justifyContent="center" alignItems="center">
              <Image src={clickedImage} alt='Clicked Image' maxH="50vh" maxW="50vw" />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ProfileContainer;
