import React, { useState } from "react";
import { Flex,Center, Heading, VStack, Box, HStack, Image,Button, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import { BiSearch } from "react-icons/bi";
// user Imports
import { colors } from "../../globalStyles/colors";
import Feed from "../Landing/Feed";
import ClusterBar from "./ClusterBar";
import Cluster from "./Cluster";
import ClusterList from "./ClusterList";
import SelfPosts from "../profile/SelfPosts";
import ProfileComponent from "../profile/ProfileComponent";
import Entity from "../connections/friends/Entity";
import NavBar from "../Landing/NavBar";
import GeneralInputField from "../../Components/GeneralInputField";
import CreateCluster from "./CreateCluster";

export default function ClusterLand() {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userDetails = location.state?.userDetails || 'E';

  const [showClusterProfile, sethowClusterProfile] = useState(null)

  const updateShowClusterProfile = cluster => {
    sethowClusterProfile(cluster)
  }

  return (
    <Flex width='100%' bgColor='black' minH='100vh' flexDirection='row'>
      <Flex width="20%" flexDirection="column"  position="fixed" top="0" zIndex="1" height="100vh"  >
        {/* <ClusterBar userDetails={userDetails} /> */}
        <HStack justifyContent='space-between' ml='5px' mr='10px' mt={2}>
          
          
           <Image src='../../logo-2-bg-remove.png' maxW='40px' />
           <Heading variant='md' color='white'>Cluster</Heading>
          
           <NavBar userDetails={userDetails}/>
          
        </HStack>
        <Box mt="50px"/>
        <Entity userDetails={userDetails} title="My Clusters"/>
        <Center>
              <Button borderRadius='20px' width='250px' height='50px' fontSize='20px' color='whitesmoke' _hover={{bgColor:colors.primary}} bgColor={colors.primary_dim} onClick={onOpen}>Create a Cluster</Button>
            </Center>

            <CreateCluster
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                userDetails={userDetails}
                create='C'
              />
      </Flex>
      
      <Flex flex="3" ml="20%" width="50%">
        {showClusterProfile != null ? <ProfileComponent updateShowUserProfile={updateShowClusterProfile} user={userDetails} name={showClusterProfile?.clusterName} type='C' /> : null}
        <Feed cluster={showClusterProfile} scope='C' />
        {/* <SelfPosts /> */}
      </Flex>


     
      <Flex width="25%" height="90vh" mr="10px" mt="20px" flexDirection="column" position="fixed" top="0" right="5" zIndex="1" >
        <ClusterList userDetails={userDetails} updateShowClusterProfile={updateShowClusterProfile} />
        <Box mt={5}/>
          <GeneralInputField alignSelf='center' icon={<BiSearch color='whitesmoke' />} height='45px' placeholder="Search a friend, cluster, trending topics" width='90%'/> 
        <Box mt={2}/> 
      </Flex>
    </Flex>
  );
}
