import React from "react";
import { ChakraProvider, Box, Heading, Text, Divider } from "@chakra-ui/react";

function TermsOfUse() {
  return (
    <ChakraProvider>
      <Box bg="black" color="white" minHeight="100vh" p={8}>
        <Heading as="h1" fontSize="3xl" mb={6}>
          Terms of Usage
        </Heading>

        <Text>
          Welcome to Cluster! Please read these terms carefully before accessing or using our application.
        </Text>

        <Section heading="1. Acceptance of Terms">
          <Text>
            By accessing or using the Cluster application ("the Service"), you agree to be bound by these Terms of Use. If you do not agree to all the terms and conditions of this agreement, then you may not access the Service.
          </Text>
        </Section>

        <Section heading="2. Use of the Service">
          <Text>
            You agree to use the Service only for lawful purposes and in a manner that does not infringe upon the rights of others. Specifically, you agree not to post or share any content that is vulgar, offensive, or otherwise inappropriate. The Cluster team reserves the right to remove any content that violates these guidelines and to take appropriate action against the user, including banning the account or taking community action.
          </Text>
        </Section>

        <Section heading="3. Beta Version">
          <Text>
            Please note that this is a beta version of the Cluster application and does not represent the final version. We appreciate your feedback and patience as we continue to improve and develop the service.
          </Text>
        </Section>

        <Section heading="4. User Conduct">
          <Text>
            Users are prohibited from engaging in any conduct that disrupts or interferes with the proper functioning of the Service or the experience of other users. This includes but is not limited to harassment, spamming, or posting harmful content.
          </Text>
        </Section>

        <Section heading="5. Intellectual Property">
          <Text>
            All content posted on the Service is the property of the respective users. By posting content on the Service, you grant Cluster a non-exclusive, royalty-free license to use, modify, and distribute the content as necessary for the operation of the Service.
          </Text>
        </Section>

        <Section heading="6. Privacy">
          <Text>
            Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal information.
          </Text>
        </Section>

        <Section heading="7. Termination">
          <Text>
            The Cluster team reserves the right to terminate or suspend your account at any time without prior notice for any reason, including violation of these Terms of Use.
          </Text>
        </Section>

        <Section heading="8. Changes to Terms">
          <Text>
            Cluster reserves the right to modify or revise these Terms of Use at any time. Your continued use of the Service following the posting of any changes constitutes acceptance of those changes.
          </Text>
        </Section>

        <Section heading="9. Contact Us">
          <Text>
            If you have any questions or concerns about these Terms of Use or our service, please contact us at [contact@email.com].
          </Text>
        </Section>
      </Box>
    </ChakraProvider>
  );
}

function Section({ heading, children }) {
  return (
    <Box mb={6}>
      <Heading as="h2" fontSize="2xl" mb={4} mt={6}>
        {heading}
      </Heading>
      {children}
      <Divider borderColor="white" />
    </Box>
  );
}

export default TermsOfUse;
