import React from "react";
import { Flex, HStack, Avatar, Text } from "@chakra-ui/react";
import { colors } from "../../globalStyles/colors";

export default function Message({ val, index, localUser, recipient }) {
  const messageStyle = {
    sent:{
      alignItems:"flex-end"
    }, 
    received:{
      alignItems:"flex-start"
    }
  }

  const styles = {
    alignItems:messageStyle[val.type]?.alignItems
  }
  // console.log(localUser)
  // console.log(recipient)
  // console.log(val)
    // console.log(messageStyle[val.type]?.alignItems)
  return (
    val.message && (
      <Flex
        flexWrap="wrap"
        width="100%"
        mt="10px"
        // ml="10px"
        // mr="20px"
       {...styles}
       flexDirection="column"
       height="auto"
        key={index} // Use the index as the key
       
      >
        <Flex
        flexWrap="wrap"
        width="70%"
        mt="10px"
        // ml="10px"
        // mr="20px"
       {...styles}
       flexDirection="column"
       height="auto"
        key={index} // Use the index as the key
        
      >
        <HStack>
          {val.type === "received" && <Avatar ml={2} size="sm" name={val?.recipient || 'Cluster'} src={(val.scope === 'cluster' || val.scope === 'enclave') ? val?.senderProfileImage : recipient?.profileImageUrl} />}
          <Text
            color='white'
            borderRadius="10px"
            p="10px"
            bgColor={val.type === "received" ? 'blue.400' :colors.primary}
          >
            {val.message}
          </Text>
          {val.type === "sent" && <Avatar mr={2} size="sm" name={val?.sender || 'Cluster'} src={localUser?.profileImageUrl
} />}
        </HStack>
        </Flex>
      </Flex>
    )
  );
}