import React from 'react';
import { Button, Flex, Box,Text } from '@chakra-ui/react';
import { colors } from '../globalStyles/colors';

export default function MenuButton({ button_icon_margin, text, size, onClick, fontSize, width, height, Icon, isLoading, spinner, type, bgColor, ...rest }) {
  const buttonSizes = {
    sm: {
      width: '80px',
      height: '30px',
      fontSize: '14px',
    },
    md: {
      width: '100px',
      height: '40px',
      fontSize: '18px',
    },
    lg: {
      width: '150px',
      height: '50px',
      fontSize: '20px',
    },
    sl: {
      width: '100%',
      height: '50px',
      fontSize: '20px'
    },
    c: {
      width: width,
      fontSize: fontSize,
      height: height
    }
  };

  const buttonStyle = {
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    width: buttonSizes[size]?.width || '100px',
    height: buttonSizes[size]?.height || '40px',
    fontSize: fontSize || buttonSizes[size]?.fontSize || '18px',
    borderWidth: bgColor ? '0px' : '2px',
    borderColor: bgColor || 'black',
    bgColor: bgColor ? '' : 'transparent',
    _hover: { bgColor: bgColor ? '' : 'black' },
    _active: { color: 'rgb(229, 224, 177, 0.6)' },
  };

  return (
    <Button onClick={onClick} variant={type || 'ghost'} isLoading={isLoading} spinner={spinner} {...buttonStyle} {...rest}>
      <Flex align="center" width="100%" >
        <Box mr={button_icon_margin}>{Icon}</Box>
        <Text fontSize={fontSize}>{text}</Text>
      </Flex>
    </Button>
  );
}
