import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Center,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  useDisclosure
} from "@chakra-ui/react";
import { RxCross2 } from "react-icons/rx";
// user Imports
import ProfileContainer from "./ProfileContainer";
import SelfPosts from "./SelfPosts";
import { colors } from "../../globalStyles/colors";
import ProfileAttributeContainer from "./ProfileAttributeContainer";
import ProfileButtonsContainer from "./ProfileButtonsContainer";
import axios from "axios";

export default function ProfileComponent({ user, name, type, updateShowUserProfile }) {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!user) return;

    onOpen();
    setIsLoading(true);
    const fetchClusterDetails = async () => {
      let url;
      if (type === 'C')
        url = `${process.env.REACT_APP_SERVER_BASE_URL}/cluster/getCluster/${name}`;
      else if (type === 'P')
        url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/details/${name}`;
      else return;

      await axios.get(url)
        .then(res => {
          setProfile(res.data);
        })
        .catch(error => console.error("Error fetching cluster:", error));
      setIsLoading(false);
    };
    fetchClusterDetails();
  }, [name, type, user, onOpen]);

  const handleShowProfile = user => {
    setProfile(null)
    updateShowUserProfile(user);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" onOverlayClick={() => handleShowProfile(null)}>
      <ModalOverlay bgColor='blackAlpha.800' />
      <ModalContent bgColor="rgb(10, 10, 10)">
        <Flex flexDirection="column">
          <Center>
            {isLoading ? (
              <Spinner />
            ) : (
              profile && (
                <Box
                  width="100%"
                  // mt="10px"
                  maxW="xl"
                  borderRadius="5px"
                  position="relative"
                  boxShadow="0px 1px 5px grey"
                  // borderWidth='0px 1px 1px 1px' borderColor='grey'
                >
                  <IconButton
                    variant="ghost"
                    fontSize="1.5rem"
                    color={colors.primary}
                    aria-label="Close profile"
                    icon={<RxCross2 />}
                    _hover={{ bgColor: "black" }}
                    zIndex={2}
                    position="absolute"
                    top="10px"
                    right="10px"
                    onClick={() => handleShowProfile(null)}
                  />
                  <ProfileContainer profile={profile} type={type} attribute='D' />
                  <Box mt="60px"></Box>
                  <ProfileAttributeContainer profile={profile} type={type} />
                  <Box mt="40px"></Box>
                  <ProfileButtonsContainer user={user} profile={profile} type={type} />
                  <Box mt="20px"></Box>
                </Box>
              )
            )}
          </Center>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
