import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Divider,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  FormControl,
  ModalFooter,
  Input,
  useDisclosure,
  HStack,
  useToast,
  ModalContent,
  Image, 
  VStack
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import GeneralButton from "../Components/GeneralButton";
import { FallingLines } from "react-loader-spinner";
import { colors } from "../globalStyles/colors";

export default function LoginLanding() {
  const [searchParams] = useSearchParams();
  const eCode = searchParams.get('eCode');
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputType, setInputType] = useState('email');
  const [buttonMessage, setButtonMsg] = useState('Use Phone');
  const [title, setTitle] = useState('Email');
  const [isSLoading, setSLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  console.log(eCode)

  const [cookies, setCookie] = useCookies(['clusterWebToken']);

  useEffect(()=> {
    
    if(eCode === '842157'){
      
      toast({
        title: "Login Error", 
        description:`Account already exists with this email,
                  use password to login`,
        status:"error",
        position:"top-right", 
        isClosable:true,
        variant:"solid", 
        duration:5000
      })
      navigate('/login')
    }
    else if(eCode === '842160'){
      toast({
        title: "Login Error", 
        description:`User doesn't exist`,
        status:"error",
        position:"top-right", 
        isClosable:true,
        variant:"solid", 
        duration:5000
      })
      navigate('/login')
    }
   
  }, [eCode])

  const onPhoneClick = () => {
    if (inputType === 'email') {
      setInputType('tel')
      setButtonMsg('use email')
      setTitle('Mobile Number')
    } else {
      setInputType('email')
      setButtonMsg('Use Phone')
      setTitle('Email')
    }
  }

  const handleSignIn = async () => {
    if(email === '' || password === ''){
      setSLoading(false)
        toast({
          title: "Invalid Input",
          description: 'Enter email and password to continue.',
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'yellow',
        });
        return;
    }
    const url = inputType === "email" ? `${process.env.REACT_APP_SERVER_BASE_URL}/auth/email` : `${process.env.REACT_APP_SERVER_BASE_URL}/auth/mobile`;

    const data = {
      "email": email,
      "password": password,
    }
    try {
      const response = await axios.post(url, data, { withCredentials: true }).then(
        res => {
          console.log(res);
          return res
        }
      ).catch(err => {
        setSLoading(false)
        console.log(err)
        const error = err.response.data.message;
        toast({
          title: "Invalid User",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'yellow',
        });
        return err;
      })

      if (response.status === 200) {
        setSLoading(false)
        navigate('/home', { state: { email: response.data.data } })
      }else if(response.status == 202){
        navigate('/register', {state: {email : response.data.data}})
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleForgotPassword = async () => {
    if(forgotEmail === '')
      {
        setIsLoading(false)
        toast({
          title: "Invalid Input",
          description: 'Enter email to continue.',
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'red',
        });
        return;
      }
    setIsLoading(true)
    try {
      
      const response  = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/auth/forgot-password`, { email: forgotEmail })
      toast({
        title: "Success",
        description: "Recovery email sent",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false)
      onClose();
    } catch (error) {
      setIsLoading(false)
      toast({
        title: "Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bgColor="black" h="100vh" w="100vw">
      <Center h="100vh">
        <Box bgColor="black" p="10">
          <Flex direction="row" align="center" justify="center">
          <Stack textAlign="center" spacing={3}>
            <Image  src="../../LogoOrange-bg-remove.png"/>
            </Stack>
            <Center height="200px" m="5">
              <Divider orientation="vertical" />
            </Center>
            <Stack textAlign="center" spacing={3}>
              <Heading size="sm" color="white">
                Glad to see you back. We missed you!
              </Heading>
              <FormControl>
                <FormControl>
                   {/* <Input
            height='50px'
            placeholder={title}
            value={value}
            type={inputType}
            onChange={(e) => setValue(e.target.value)}
            borderColor="orange"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
            _placeholder={{ color: 'rgb(255, 250, 240)'}}
            bgColor="orange"
            _hover={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            _focus={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            width='90%'
          /> */}
                  <Input errorBorderColor='red.300' focusBorderColor={colors.primary} borderColor="grey" placeholder={title} color="white" _placeholder={{ opacity: 0.5, color: 'rgb(229, 224, 177)' }} type={inputType} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                </FormControl>

                <HStack alignContent='end'>
                  <Button variant='link' size='xs' colorScheme='yellow' onClick={onPhoneClick} mt={2} mb={4}>{buttonMessage}</Button>
                </HStack>

                <FormControl mb="2">
                  <Input placeholder={title === "Email" ? "password" : "OTP"} borderColor="grey" color="white" focusBorderColor={colors.primary} _placeholder={{color: 'grey' }} type={inputType === "email" ? "password" : "number"} value={password} onChange={(e) => { setPassword(e.target.value) }} />
                </FormControl>
              <VStack mt={5} spacing={4}>
                <GeneralButton text="Sign In" size="c" height='40px' isLoading={isSLoading} spinner={<FallingLines
                  color="rgb(229,224,177)"
                  width="50"
                  height="50"
                  visible={true}
                  opacity={1}
                  ariaLabel="falling-circles-loading"
                />} onClick={
                  () => {
                    setSLoading(true);
                    handleSignIn()
                  }}>
                </GeneralButton>

                <GeneralButton height='40px' fontSize='18px' bgColor='whitesmoke' bdColor='white' txtColor='orange' text="Sign Up" size="c" spinner={<FallingLines
                  color="rgb(229,224,177)"
                  width="50"
                  height="50"
                  visible={true}
                  opacity={1}
                  ariaLabel="falling-circles-loading"
                />} onClick={
                  () => {
                    navigate('/')
                  }}>
                </GeneralButton>
               
                <Button variant='link' size='xs' colorScheme='yellow' mt={2} mb={4} onClick={onOpen}>
                  Forgot Password?
                </Button>
              </VStack>
              </FormControl>
            </Stack>
          </Flex>
        </Box>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose} isCentered >
        <ModalOverlay />
        <ModalContent  bgColor="rgb(255, 189, 69)" textColor='rgb(255, 250, 240)'>
          <ModalHeader>Reset Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
            <Input
            placeholder="Enter your email"
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
            borderColor="orange"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
            _placeholder={{ color: 'rgb(255, 250, 240)'}}
            bgColor="rgb(250, 171, 30)"
            _hover={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            _focus={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
          />

            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button borderColor="orange" bgColor="rgb(250, 171, 30)" textColor='rgb(255, 250, 240)'
            _hover={{backgroundColor:"orange"}}
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"  mr={3} onClick={handleForgotPassword} isLoading={isLoading} spinner={<FallingLines
              color="rgb(229,224,177)"
              width="50"
              height="50"
              visible={true}
              opacity={1}
              ariaLabel="falling-circles-loading"
            />}>
              Send Reset Link
            </Button>
            <Button variant="ghost" _hover={{backgroundColor:"orange"}}  onClick={onClose} textColor='rgb(255, 250, 240)' >Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
