import { Image, keyframes } from "@chakra-ui/react";

export default function LogoSpinner({theme = 'L', ...rest}) {
    // Define the keyframes for the spinning animation
    const spin = keyframes`
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    `;

    // Define the animation style
    const animation = `${spin} infinite 1.2s linear`;

    if(theme === 'D')
    return (
        <Image 
            src='../favicon.png' 
            minw='50px' 
            animation={animation}
            {...rest}
        />
    );

    if(theme === 'L')
        return (
            <Image 
                src='../logo-2-bg-remove.png' 
                animation={animation}
                {...rest}
            />
        );
}
