import React, { useEffect, useState } from "react";
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import {
    Box,
  FormControl,
  Input,
  FormLabel,
  Flex,
  Heading,
  Center,
  RadioGroup,
  HStack,
  Radio,
  Text,
  FormHelperText,
  Textarea
} from "@chakra-ui/react";
import GeneralButton from "../Components/GeneralButton";
import { colors } from "../globalStyles/colors";
import { useLocation } from "react-router-dom";

export default function Feedback() {
  const [isLoading, setIsLoading] = useState(false);
  const [overallRating, setOverAllRating] = useState(null);
  const [likedConcept, setLikedConcept] = useState(null);
  const [likedUIExperience, setLikedUIExperience] = useState(null);
  const [triedChatting, setTriedChatting] = useState(null);
  const [triedCreatingCluster, setTriedCreatingCluster] = useState(null);
  const [likelyRecommendation, setLikelyRecommendation] = useState(null);
  const [futureUseClusterMobileApp, setFutureUseClusterMobileApp] = useState(null);
  const [whatUseFor, setWhatUseFor] = useState("");
  const [feedback, setFeedback] = useState("");
  const [suggestions, setSuggestions] = useState("");
  // Define state variables for each set of ratings
const [conceptRating, setConceptRating] = useState(null);
const [uiRating, setUIRating] = useState(null);
const [chattingRating, setChattingRating] = useState(null);
const [clusterRating, setClusterRating] = useState(null);
const [recommendRating, setRecommendRating] = useState(null);
const [isSubmitted, setSubmitted] = useState(false)
// Add more state variables for other ratings as needed

const userDetails = useLocation().state?.userDetails;

useEffect(() => {
    const checkSubmission = async () => {
       try{
        console.log(userDetails)
        const response =  await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/feedback/check/user-feedback`, {params: {userDetailsId: userDetails._id}})
        console.log(response)
        if(response.status === 200)
            setSubmitted(true)
        else if(response.status === 201)
            setSubmitted(false)
       }catch(e){

       }
       finally{

       }
    }
    checkSubmission()
}, [userDetails])

// Event handlers for each set of ratings
const handleConceptRatingChange = (value) => {
  setConceptRating(parseInt(value));
};

const handleUIRatingChange = (value) => {
  setUIRating(parseInt(value));
};

const handleChattingRatingChange = (value) => {
  setChattingRating(parseInt(value));
};

const handleClusterRatingChange = (value) => {
    setClusterRating(parseInt(value));
  };

const handleRecommentRatingChange = (value) => {
    setRecommendRating(parseInt(value));
};
// Add more event handlers for other ratings as needed


  const handleOverallRatingChange = (value) => {
    setOverAllRating(parseInt(value)); // Convert value to integer before setting rating
  };

  const handleLikedConceptChange = (value) => {
    setLikedConcept(parseInt(value));
  };

  const handleLikedUIExperienceChange = (value) => {
    setLikedUIExperience(parseInt(value));
  };

  const handleTriedChattingChange = (value) => {
    setTriedChatting(parseInt(value));
  };

  const handleTriedCreatingClusterChange = (value) => {
    setTriedCreatingCluster(parseInt(value));
  };

  const handleLikelyRecommendationChange = (value) => {
    setLikelyRecommendation(parseInt(value));
  };

  const handleFutureUseClusterMobileAppChange = (value) => {
    setFutureUseClusterMobileApp(parseInt(value));
  };

  const handleWhatUseForChange = (event) => {
    setWhatUseFor(event.target.value);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSuggestionsChange = (event) => {
    setSuggestions(event.target.value);
  };

  const handleSubmit = async () => {
    const data = {
        likedConcept,
        likedUIExperience,
        triedChatting,
        triedCreatingCluster,
        likelyRecommendation,
        futureUseClusterMobileApp,
        whatUseFor,
        feedback,
        suggestions,
        conceptRating,
        uiRating,
        chattingRating,
        clusterRating,
        recommendRating,
        overallRating, 
        userDetailsId : userDetails._id
    }
    console.log(data)
    try{
        const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/feedback/user-feedback`, data)
        console.log(response)
        if(response.status == 201){
            setSubmitted(true)
        }

        
    }
    catch(err){

    }finally{
        setIsLoading(false);
    }
    // Add logic to handle form submission here
  };

if(isSubmitted){
    return <Flex
    bgColor={colors.bgDark}
    minH="100vh"
    height="100%"
    width="100%"
    alignItems="center" // Align items in the cross-axis (vertically)
    justifyContent="center" // Align items in the main axis (horizontally)
    flexDirection="column"
  >

  <Center>
      <Heading size="xl" color="rgb(229,224,177)" m={5}>
        Cluster
      </Heading>
    </Center>

    <Center>
      <Heading size="lg" color="rgb(229,224,177)" mb={5}>
        Feedback & Suggestions
      </Heading>
    </Center>

    <Center>
      <Heading size="lg" color="rgb(229,224,177)" mb={5}>
        We have received your response, Thankyou :)
      </Heading>
    </Center>
    </Flex>
}

  return (
    <Flex
      bgColor={colors.bgDark}
      minH="100vh"
      height="100%"
      width="100%"
      alignItems="center" // Align items in the cross-axis (vertically)
      justifyContent="center" // Align items in the main axis (horizontally)
      flexDirection="column"
    >

    <Center>
        <Heading size="xl" color="rgb(229,224,177)" m={5}>
          Cluster
        </Heading>
      </Center>

      <Center>
        <Heading size="lg" color="rgb(229,224,177)" mb={5}>
          Feedback & Suggestions
        </Heading>
      </Center>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          Did you like the concept of Cluster?
        </FormLabel>
        <RadioGroup value={likedConcept} onChange={handleLikedConceptChange} colorScheme="yellow">
          <HStack spacing="24px">
            <Radio value={1}>
              <Text color={colors.primary}>Yes</Text>
            </Radio>
            <Radio value={0}>
              <Text color={colors.primary}>No</Text>
            </Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          How well would you rate the concept?
        </FormLabel>
        <RadioGroup value={conceptRating} onChange={handleConceptRatingChange} colorScheme="yellow">
          <HStack spacing={4}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Radio key={value} value={value}>
                <Text color={colors.primary}>{value}</Text>
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
        <FormHelperText>Your rating will help us improve your experience.</FormHelperText>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          Did you like the UI experience?
        </FormLabel>
        <RadioGroup value={likedUIExperience} onChange={handleLikedUIExperienceChange} colorScheme="yellow">
          <HStack spacing="24px">
            <Radio value={1}>
              <Text color={colors.primary}>Yes</Text>
            </Radio>
            <Radio value={0}>
              <Text color={colors.primary}>No</Text>
            </Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          How would you rate the UI experience of Cluster?
        </FormLabel>
        <RadioGroup value={uiRating} onChange={handleUIRatingChange} colorScheme="yellow">
          <HStack spacing={4}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Radio key={value} value={value}>
                <Text color={colors.primary}>{value}</Text>
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
        <FormHelperText>Your rating will help us improve your experience.</FormHelperText>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          Did you try chatting with someone?
        </FormLabel>
        <RadioGroup value={triedChatting} onChange={handleTriedChattingChange} colorScheme="yellow">
          <HStack spacing="24px">
            <Radio value={1}>
              <Text color={colors.primary}>Yes</Text>
            </Radio>
            <Radio value={0}>
              <Text color={colors.primary}>No</Text>
            </Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          How was your chatting experience?
        </FormLabel>
        <RadioGroup value={chattingRating} onChange={handleChattingRatingChange} colorScheme="yellow">
          <HStack spacing={4}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Radio key={value} value={value}>
                <Text color={colors.primary}>{value}</Text>
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
        <FormHelperText>Did you know your messages are 256-bit encrypted?</FormHelperText>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          Did you try creating a Cluster/Enclave?
        </FormLabel>
        <RadioGroup value={triedCreatingCluster} onChange={handleTriedCreatingClusterChange} colorScheme="yellow">
          <HStack spacing="24px">
            <Radio value={1}>
              <Text color={colors.primary}>Yes</Text>
            </Radio>
            <Radio value={0}>
              <Text color={colors.primary}>No</Text>
            </Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          How do you feel about creating a Cluster/Enclave?
        </FormLabel>
        <RadioGroup value={clusterRating} onChange={handleClusterRatingChange} colorScheme="yellow">
          <HStack spacing={4}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Radio key={value} value={value}>
                <Text color={colors.primary}>{value}</Text>
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
        <FormHelperText>Your rating will help us improve your experience.</FormHelperText>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          Would you like to use Cluster in daily life?
        </FormLabel>
        <RadioGroup value={likelyRecommendation} onChange={handleLikelyRecommendationChange} colorScheme="yellow">
          <HStack spacing="24px">
            <Radio value={1}>
              <Text color={colors.primary}>Yes</Text>
            </Radio>
            <Radio value={0}>
              <Text color={colors.primary}>No</Text>
            </Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          How likely do you feel to recommend someone to try Cluster?
        </FormLabel>
        <RadioGroup value={recommendRating} onChange={handleRecommentRatingChange} colorScheme="yellow">
          <HStack spacing={4}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Radio key={value} value={value}>
                <Text color={colors.primary}>{value}</Text>
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
        <FormHelperText>Your rating will help us improve your experience.</FormHelperText>
      </FormControl>

      <FormControl width="50%" isRequired> m={3}
        <FormLabel color="rgb(229,224,177)">
          How was your overall Cluster experience?
        </FormLabel>
        <RadioGroup value={overallRating} onChange={handleOverallRatingChange} colorScheme="yellow">
          <HStack spacing={4}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Radio key={value} value={value}>
                <Text color={colors.primary}>{value}</Text>
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
        <FormHelperText>Your rating will help us improve your experience.</FormHelperText>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          Would you like to try Cluster Mobile App in future?
        </FormLabel>
        <RadioGroup value={futureUseClusterMobileApp} onChange={handleFutureUseClusterMobileAppChange} colorScheme="yellow">
          <HStack spacing="24px">
            <Radio value={1}>
              <Text color={colors.primary}>Yes</Text>
            </Radio>
            <Radio value={0}>
              <Text color={colors.primary}>No</Text>
            </Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      <FormControl width="50%" m={3}>
        <FormLabel color="rgb(229,224,177)">
          What will you use Cluster for?
        </FormLabel>
        <Input
          placeholder="Type here"
          color="white"
          onChange={handleWhatUseForChange}
        />
        <FormHelperText>Your feedback will help us improve your experience.</FormHelperText>
      </FormControl>

      <FormControl width="50%" isRequired m={3}>
        <FormLabel color="rgb(229,224,177)">
          Any suggestions/feedback you would like to give?
        </FormLabel>
        <Textarea
          placeholder="Type here"
          color="white"
          onChange={handleFeedbackChange}
        />
        <FormHelperText>Your feedback will help us improve your experience.</FormHelperText>
      </FormControl>

      <FormControl width="50%" m={3}>
        <FormLabel color="rgb(229,224,177)">
          Any feature you wish to have in Cluster?
        </FormLabel>
        <Textarea
          placeholder="Type here"
          color="white"
          onChange={handleSuggestionsChange}
        />
        <FormHelperText>Your suggestions will help us improve your experience.</FormHelperText>
      </FormControl>
        
        <Box m={3}></Box>
      <FormControl width="50%">
      <GeneralButton
        text="Submit"
        size="sl"
        isLoading={isLoading}
        spinner={<BarLoader width="300px" height="4px" color="rgb(229,224,177)" />}
        onClick={() => {
            setIsLoading(true)
            handleSubmit()}}
      />
      </FormControl>
       <Box m={5}></Box>
    </Flex>
  );
}
