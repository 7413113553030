import React from 'react';
import { Button } from '@chakra-ui/react';

export default function GeneralButton({ txtColor ,leftIcon, bgColor,bdColor, text, size, onClick, fontSize, width, height, Icon, isLoading, spinner,type }) {
  const buttonSizes = {
    sm: {
      width: '80px',
      height: '30px',
      fontSize: '14px',
    },
    md: {
      width: '150px',
      height: '50px',
      fontSize: '18px',
    },
    lg: {
      width: '200px',
      height: '50px',
      fontSize: '18xpx',
    },
    sl:{
      width:'90%',
      height:'50px',
      fontSize:'20px'
    },
    c:{
      width:width ||'100%',
      fontSize:fontSize || '20px',
      height:height || '100%'
    }
  };

  // <Button borderColor="orange" bgColor="rgb(250, 171, 30)" textColor='rgb(255, 250, 240)'
  //           _hover={{backgroundColor:"orange"}}
  //           boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"  mr={3} onClick={handleForgotPassword} isLoading={isLoading} spinner={<FallingLines
  //             color="rgb(229,224,177)"
  //             width="50"
  //             height="50"
  //             visible={true}
  //             opacity={1}
  //             ariaLabel="falling-circles-loading"
  //           />}></Button>

  const buttonStyle = {
    color: 'rgb(229, 224, 177)',
    borderRadius: '25px',
    cursor: 'pointer',
    width: buttonSizes[size]?.width || '100px',
    height: buttonSizes[size]?.height || '40px',
    fontSize: buttonSizes[size]?.fontSize || '18px',
    borderWidth: '2px',
    borderColor: bdColor ? bdColor : 'orange',
    bgColor: bgColor ? bgColor : "rgb(250, 171, 30)",
    _hover: {backgroundColor:"orange", textColor:'white', borderColor:'orange'},
    boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)",
    _active: { bgColor: 'orange' },
    textColor: txtColor ? txtColor : 'rgb(255, 250, 240)'
  };

  return (
    <Button onClick={onClick} leftIcon={leftIcon} rightIcon={Icon} isLoading={isLoading} spinner={spinner} {...buttonStyle}>
      {text}
    </Button>
  );
}
