import React, {useEffect, useState} from "react";
import {
  Flex,
  Box,
  Text,
  IconButton,
  HStack,
  VStack,
  Center,
  Divider,
  Spacer,
  useToast
} from "@chakra-ui/react";
import { IoAddOutline } from "react-icons/io5";
import { FaLocationArrow } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { IoOpenOutline } from "react-icons/io5";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// user Imports
import { colors } from "../../globalStyles/colors";
import ListItemBox from "../../Components/ListItemBox";
import CreateCluster from "./CreateCluster";



export default function Enclave({title, clusterName, userDetails, enclaveName, scope}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {

    const fetchData = async () => {
      var url
      if(title === "Enclaves"){
       url = `${process.env.REACT_APP_SERVER_BASE_URL}/enclaves/get/cluster-enclaves`
      }
      else if(title === "Members"){
        url = `${process.env.REACT_APP_SERVER_BASE_URL}/cluster/get/allmembers`
      }else if(title === "Enclave Members")
        url =`${process.env.REACT_APP_SERVER_BASE_URL}/enclaves/get/allmembers`
      try {
        const response = await axios.get(url, {params : {user : userDetails?.userName, clusterName:  clusterName, enclaveName : enclaveName }});
        console.log(response)
        setData(response.data);
        if(response.status === 200)
          setError(null)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  },[userDetails]);

  const handleOpenEnclave = user =>{
    navigate(`/enclave?cluster=${clusterName}&enclave=${user.enclaveName}`, {state : {user : userDetails}})
  }

  const handleOpenMember = user => {
    toast({
      title: "Coming Soon!",
      description: "Personal Chatting in Enclave/Cluster coming soon.",
      status: "info",
      duration: 5000,
      isClosable: true,
      variant: "solid",
      position:'top-left'
      // colorScheme: 'yellow',
    })
  }

  if (loading) {
    return <Center>
    <Box
      borderColor={colors.primary_dim}
      // borderRadius="2px"
      // borderWidth="1px 0px 1px 0px"
      height="250px"
      width="100%"
      // bgColor={colors.bglight}   
    >
      <Box
        boxShadow={`0px 2px 4px ${colors.bgDark}`} borderRadius="lg"
        // color={colors.primary}
        bgColor='grey'
      >
        <HStack>
          <Text
            fontSize="14"
            color={colors.textDark}
            p="5px"
            fontWeight="Bold"
          >
            {title}
          </Text>
          <Spacer/>
         
          <CreateCluster clusterName={clusterName} userDetails={userDetails} create='E' />
          <IconButton
          icon={<FaLocationArrow />}
          _hover={{ bgColor: colors.primary }}
          variant="ghost"
          color={colors.textDark}
          fontSize="1rem"
        />
        </HStack>
      </Box>
    {/* <Heading>Error</Heading> */}
    </Box>
    </Center>
    // </Box>
  }

  if (error) {
    return <Center>
    <Box
      borderColor={colors.primary_dim}
      // borderRadius="2px"
      // borderWidth="1px 0px 1px 0px"
      height="250px"
      width="100%"
      // bgColor={colors.bglight}   
      mr={1}
    >
      <Box
        boxShadow={`0px 2px 4px ${colors.bgDark}`} borderRadius="lg"
        // color={colors.primary}
        bgColor='grey'
      >
        <HStack>
          <Text
            fontSize="14"
            color={colors.textDark}
            p="5px"
            fontWeight="Bold"
          >
            {title}
          </Text>
          <Spacer/>
         
          <CreateCluster clusterName={clusterName} userDetails={userDetails} create='E' />
          <IconButton
          icon={<FaLocationArrow />}
          _hover={{ bgColor: colors.primary }}
          variant="ghost"
          color={colors.textDark}
          fontSize="1rem"
        />
        </HStack>
  </Box>
  {/* <Heading>Error</Heading> */}
  </Box>
  </Center>
  }

  return (
    <>
      <Center>
      <Box
      borderColor={colors.primary_dim}
      // borderRadius="2px"
      // borderWidth="1px 0px 1px 0px"
      height="250px"
      width="100%"
      // bgColor={colors.bglight}   
      mr={1}
    >
      <Box
        boxShadow={`0px 2px 4px ${colors.bgDark}`} borderRadius="lg"
        // color={colors.primary}
        bgColor='grey'
      >
            <HStack>
              <Text
                fontSize="14"
                color={colors.textDark}
                p="5px"
                fontWeight="Bold"
              >
                {title}
              </Text>
              <Spacer/>
              {/* <IconButton
                icon={<IoAddOutline />}
                _hover={{ bgColor: colors.primary }}
                variant="ghost"
                color={colors.textDark}
                fontSize="1.7rem"
                onClick={onOpen}
              /> */}
              <CreateCluster clusterName={clusterName} userDetails={userDetails} create='E' />
              <IconButton
              icon={<FaLocationArrow />}
              _hover={{ bgColor: colors.primary }}
              variant="ghost"
              color={colors.textDark}
              fontSize="1rem"
            />
            </HStack>
          </Box>

          <VStack
            align="stretch"
            overflowY="auto"
            height="80%"
            sx={{
              "&::-webkit-scrollbar": {
                w: "2",
                bg: `grey.100`,
                h:'2'
              },
              "&::-webkit-scrollbar-track": {
                w: "6",
                bg: `grey.100`,
                h:'2'
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10",
                bg: `yellow.100`,
              },
            }}
          >
            {title === "Enclaves" &&data && data.map((user, index) => (
            <ListItemBox image={user?.profileImageUrl} key={index} text={user.enclaveName} isOnline="N" bgColor={colors.primary_dim}
           onClick={() => handleOpenEnclave(user)} onTextClick={() => handleOpenEnclave(user)} icon={ <IoOpenOutline color={colors.primary}  />} />
          ))}

          {title === "Members" &&data && data.map((user, index) => (
            <ListItemBox image={user?.profileImageUrl} key={index} text={user.userName} isOnline="N" bgColor={colors.primary_dim}
           onClick={() => handleOpenMember(user)} onTextClick={() => handleOpenMember(user)} />
          ))}

          {title === "Enclave Members" &&data && data.map((user, index) => (
            <ListItemBox image={user?.profileImageUrl} key={index} text={user.userName} isOnline="N" bgColor={colors.primary_dim}
           onClick={() => handleOpenMember(user)} onTextClick={() => handleOpenMember(user)}  />
          ))}
          </VStack>
        </Box>
      </Center>
    </>
  );
}
