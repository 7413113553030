import { Text, Flex, Avatar, HStack } from "@chakra-ui/react";
import React, {useEffect, useState} from "react";


import Message from "./Message";
import { decryption } from "../../enc_dec/Encryption";
import axios from "axios";

export default function Messages({socket, localUser, recipient, type, clusterName}) {
  const [messages, setMessages] = useState([])

  useEffect(() => {
    const fetchMessages = async () => {
      if (type === 'C') {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/messages/cluster/fetchMessages`, { params: { clusterName: recipient.clusterName } });
          const fetchedMessages = response.data;
          setMessages(prevMessages => [
            // ...prevMessages,
            ...fetchedMessages.map(message => ({
              message: decryption(message.message, `${recipient.clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`),
              type: message.sender === localUser.userName ? "sent" : "received",
              scope: message.scope, 
              senderProfileImage: message.senderProfileImage
            }))
          ]);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }

      if (type === 'E') {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/messages/enclave/fetchMessages`, { params: { enclaveName: recipient.enclaveName, clusterName:clusterName } });
          const fetchedMessages = response.data;
          setMessages(prevMessages => [
            // ...prevMessages,
            ...fetchedMessages.map(message => ({
              message: decryption(message.message, `${recipient.enclaveName}in${clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`),
              type: message.sender === localUser.userName ? "sent" : "received",
              scope: message.scope, 
              senderProfileImage: message.senderProfileImage
            }))
          ]);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }

      if (type === 'P') {
        try {
          console.log("type " + type)
          const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/messages/personal/fetchMessages`, { params: { primaryUserName: localUser.userName,  secondaryUserName:recipient.userName} });
          console.log(response)
          const fetchedMessages = response.data;
          setMessages(prevMessages => [
            // ...prevMessages,
            ...fetchedMessages.map(message => ({
              message: decryption(message.message, `${message.sender}to${message.recepient}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`),
              type: message.sender === localUser.userName ? "sent" : "received"
            }))
          ]);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };
  
    fetchMessages();
  }, [recipient]); // Adding dependencies to ensure useEffect runs when 'type' or 'recipient' changes
  

  const receiveMessage = message => {
    console.log("called")
    let type, decryptedText
  if(message.sender === localUser.userName){
    decryptedText = decryption(message.message, `${message.sender}to${message.recepient}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
    console.log(decryptedText)
    type="sent"
  }
  else{
    decryptedText = decryption(message.message, `${message.sender}to${localUser.userName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
    console.log(decryptedText)
    type = "received"
  }
  const newMessage = {message : decryptedText, type : type}
  setMessages([newMessage, ...messages])
}

const receiveClusterMessage = message => {
  console.log("called")
  console.log(`${recipient.clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
  var decryptedText = decryption(message.message, `${recipient.clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
  console.log(decryptedText)
  if(decryptedText=='')
    return
  var type = "received"
  if(message.sender === localUser.userName)
    type="sent"
  const newMessage = {message : decryptedText, type : type}
  setMessages([newMessage, ...messages])
}

const receiveEnclaveMessage = message => {
  console.log("called")
  console.log(`${recipient.enclaveName}in${clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
  var decryptedText = decryption(message.message, `${recipient.enclaveName}in${clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
  console.log(decryptedText)
  if(decryptedText=='')
    return
  var type = "received"
  if(message.sender === localUser.userName)
    type="sent"
  const newMessage = {message : decryptedText, type : type}
  setMessages([newMessage, ...messages])
}

socket.on('message', message => {
  console.log(message)
  if(message.scope === 'private' && type === 'P')
    receiveMessage(message)
  else if(message.scope === 'cluster' && type === 'C')
    receiveClusterMessage(message)
  else if(message.scope === 'enclave' && type === 'E')
    receiveEnclaveMessage(message)
})

  return (
    <Flex width="100%" height="100%" flexDirection="column-reverse" overflowY="scroll" alignSelf="flex-end" sx={{
        '&::-webkit-scrollbar': {
          w: '1',
          // bg: `yellow.100`,
          h:'1'
        },
        '&::-webkit-scrollbar-track': {
          w: '6',
          // bg: `yellow.100`,
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10',
          // bg: `yellow.100`,
        }
      }}>
      {messages.map((value,index)=><Message localUser={localUser} recipient={recipient} key={index} val={value}/>)}
    </Flex>
  );
}