import React, { useEffect, useState } from "react";

import { BsThreeDotsVertical } from "react-icons/bs";
import { BiLike, BiChat, BiShare } from "react-icons/bi";
import { Card, CardHeader, CardBody,CardFooter, Box, Flex, Avatar, IconButton, Heading, Text, Image,Button, useToast } from "@chakra-ui/react";
import { AiFillLike } from "react-icons/ai";

// user Imports
import { colors } from "../globalStyles/colors";
import axios from "axios";
import { formatDateTime } from "../util/dateFormatter";


export default function PostComponenet({post, setCommentScreenData,isCommentScreen ,userDetails}){
  
  const [isLiked, setLiked] = useState(false);
  const [likes, setLikes] = useState(post?.likeCount)

  //will be working on comments in future
  const [comments, setComments] = useState(post?.commentCount)
  
  const [updatedPost, setUpdatedPost] = useState(post)
  const toast = useToast();

  useEffect(() => {
    const fetchUserAction = async () => {
      console.log(userDetails)
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/fetch/user-post-interaction`, {params : {postId:post?._id, userDetailsId:userDetails?._id}})
      console.log(response)
      if(response.status == 200)
        setLiked(true)
    }

    fetchUserAction()
  }, [post])

  const handleLikeEvent = async post =>{
    console.log(post)
    await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/media/update/likes`, {post : post, isLiked:!isLiked, userDetailsId:userDetails?._id}).then(
      res => {
        console.log(res)
        setLiked(!isLiked)
        setLikes(res.data.likeCount)
        post.likeCount = res.data.likeCount;
        setUpdatedPost(post)
      }
    ).catch(
      err => {
        console.log(err)
        toast({
          title: "Try again later",
          description: "Coudln't perform operation at the moment.",
          status: "error", // You can use any status like "success", "error", "warning", or "info"
          duration: 5000,
          isClosable: true,
          variant: "solid", // Use "solid" for a solid background
          // Add your custom background color
          // Example: backgroundColor: "your-color-hex-code"
          // backgroundColor: "your-color-hex-code",
          
          colorScheme:'red',
        });
      }
    )
  }


  const handleCommentClickEvent = post => {
    if(isCommentScreen)
      setCommentScreenData(null);
    else  
    setCommentScreenData(post);
  }

    return (post && <Card maxW='2xl' maxH='2xl' borderColor='grey' borderRadius='0px' borderWidth='1px 1px 1px 1px' bgColor='black' color='white'>
    <CardHeader>
      <Flex spacing='4'>
        <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
          <Avatar name={post.user?.firstName + " " + post.user?.lastName} src={post.user?.profileImageUrl || 'https://bit.ly/sage-adebayo'} />
  
          <Box>
            <Heading size='sm'>{post.user?.firstName + " " + post.user?.lastName}</Heading>
            <Text fontSize="12px">@{post.user?.userName}</Text>
          </Box>
        </Flex>
        <IconButton
          variant='ghost'
          colorScheme='gray'
          aria-label='See menu'
          icon={<BsThreeDotsVertical />}
        />
      </Flex>
    </CardHeader>
    <CardBody>
      <Text>
        {post.story}
      </Text>
      <Text fontSize="10px" textColor={colors.primary_dim}>{formatDateTime(post?.uploadedAt)}</Text>
    </CardBody>
    {post?.getUrl && <Image
          objectFit="cover"
          src={post?.getUrl}
          maxH="400px"
          width="100%"
          alt="Post image"
        />}
  
    <CardFooter
      justify='space-between'
      flexWrap='wrap'
      sx={{
        '& > button': {
          minW: '136px',
        },
      }}
    >
      {!isLiked && <Button flex='1' variant='ghost' _hover={{bgColor:'black'}} leftIcon={<BiLike  /> }  color='white' onClick={ () => handleLikeEvent(post)}>
       Like {likes}
      </Button>}
      {isLiked && <Button flex='1' variant='ghost' _hover={{bgColor:'black'}} leftIcon={<AiFillLike color={colors.primary} />}  color='white' onClick={() => handleLikeEvent(post)}>
        Like {likes}
      </Button>}
      <Button flex='1' variant='ghost' _hover={{bgColor:'black'}} leftIcon={<BiChat color={colors.primary} />} color='white' onClick={() => handleCommentClickEvent(post)}>
        Comment
      </Button>
      <Button flex='1' variant='ghost' _hover={{bgColor:'black'}} leftIcon={<BiShare color={colors.primary} />} color='white'>
        Share
      </Button>
    </CardFooter>
  </Card>) 
}