import React, { useRef } from "react";
import { Box, FormControl, FormLabel, Input, Image, IconButton } from "@chakra-ui/react";
import { AiOutlinePicture } from "react-icons/ai";
import { CiCamera } from "react-icons/ci";

// user imports 
import { colors } from "../globalStyles/colors";

const ImagePicker = ({ onImageChange }) => {
  const fileInputRef = useRef(null);

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file && file.type.startsWith("image/")) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       onImageSelected(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      <FormControl>
      
        <Input
          type="file"
          id="image"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={onImageChange}
        />
      </FormControl>

      <IconButton
              variant="ghost"
              fontSize="2rem"
              color={colors.primary}
              aria-label="See menu"
              icon={<CiCamera />}
              // _hover={<Text>Post</Text>}
              _hover={{bgColor:"black"}}
              mr="5px"
              onClick={handleButtonClick}
            />

    </Box>
  );
};

export default ImagePicker;
