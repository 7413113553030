import React from "react"

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button
  } from '@chakra-ui/react'

export default function AlertDialogBox({isOpen,onCancel, onConfirm,  title, message}) {
    const cancelRef = React.useRef()
  
    return (
      <>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
         
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {title}
              </AlertDialogHeader>
  
              <AlertDialogBody>
                {message}
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCancel}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={onConfirm} ml={3}>
                  Confirm
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }