import React, { useEffect, useState } from "react";
import {
  Flex, Box, Text, IconButton, HStack, VStack, Center, Divider, Spacer, useDisclosure
} from "@chakra-ui/react";
import { FaLocationArrow } from "react-icons/fa";
import { IoOpenOutline } from "react-icons/io5";
import { IoPersonAdd } from "react-icons/io5";
import { IoAddOutline } from "react-icons/io5";

// user Imports
import { colors } from "../../globalStyles/colors";
import ListItemBox from "../../Components/ListItemBox";
import GeneralInputField from "../../Components/GeneralInputField";
import CreateCluster from "./CreateCluster";
import axios from "axios";

export default function ClusterList({ userDetails, updateShowClusterProfile }) {
  const [clusters, setClusters] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchClusters = async () => {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/getallClusters`);

      console.log(response);
      if (response.status === 200)
        setClusters([...response.data, ...clusters]);
    };

    fetchClusters();
  }, []);

  const handleClusterProfile = cluster => {
    console.log("in cluster");
    console.log(cluster);
    updateShowClusterProfile(cluster);
  };

  return (
    <>
      <Center>
        <Box
          borderColor='grey'
          borderRadius="20px"
          height="400px"
          width="100%"
          bgColor='rgb(31, 30, 30, 0.5)'
          boxShadow={`0px 5px 10px rgb(31, 30, 30, 0.8)`}
        >
          <Box
            boxShadow={`0px 2px 4px ${colors.borderLight}`}
            borderRadius="20px"
            bgColor={colors.primary_dim}
          >
            <HStack>
              <Text
                fontSize="14"
                color={colors.textDark}
                p="5px"
                fontWeight="Bold"
              >
                Explore Clusters
              </Text>
              <Spacer />
              <IconButton
                icon={<IoAddOutline />}
                _hover={{ bgColor: colors.primary }}
                variant="ghost"
                color={colors.textDark}
                fontSize="1.7rem"
                onClick={onOpen}
              />
              <CreateCluster
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                userDetails={userDetails}
                create='C'
              />
              <IconButton
                icon={<FaLocationArrow />}
                _hover={{ bgColor: colors.primary }}
                variant="ghost"
                color={colors.textDark}
                fontSize="1rem"
              />
            </HStack>
          </Box>

          <VStack
            align="stretch"
            overflowY="auto"
            height="80%"
            sx={{
              "&::-webkit-scrollbar": {
                w: "2",
                bg: `grey.100`,
                h: '2'
              },
              "&::-webkit-scrollbar-track": {
                w: "6",
                bg: `grey.100`,
                h: '2'
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10",
                bg: `yellow.100`,
              },
            }}
          >
            {clusters != null ? clusters.map((cluster, key) => (
              <ListItemBox
                key={key}
                image={cluster?.getUrl}
                text={cluster.clusterName}
                bgColor={colors.bglight}
                isOnline="N"
                textColor={colors.textDark}
                onTextClick={() => handleClusterProfile(cluster)}
                icon={<IoOpenOutline color={colors.primary} />}
                onClick={() => handleClusterProfile(cluster)}
              />
            )) : null}

          </VStack>

        </Box>

      </Center>
    </>
  );
}
