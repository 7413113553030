import React, { useEffect, useState } from "react";
import { IoIosMenu } from "react-icons/io";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Input,
  IconButton,
  Stack,
  Divider,
  Heading,
  Flex,
  Center,
  Spacer,
  VStack,
  Text
} from "@chakra-ui/react";
import { FaSun } from "react-icons/fa";
import { IoMdMoon } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IoMdHome } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { IoMdNotifications } from "react-icons/io";
import { FaMessage } from "react-icons/fa6";
import { MdVideoStable } from "react-icons/md";
// user Imports
import { colors } from "../../globalStyles/colors";
import GeneralButton from "../../Components/GeneralButton";
import MenuButton from "../../Components/MenuButton";

export default function ClusterNav({userDetails, clusterName, updateClusterChatScreenData}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [theme, setTheme] =  useState('D');
  const navigate = useNavigate();
  const [cluster, setCluster] = useState(null);

  useEffect(() => {
    const fetchClusterDetails = async () => {
        if(clusterName)
            await axios.get(`${process.env.SERVER_BASE_URL}/cluster/getCluster/${clusterName}`).then(res => setCluster(res.data))
        .catch(err => console.log(err))
    }
    fetchClusterDetails()
  }, [clusterName])

  const handleProfileClick = () => {
    navigate('/profile',{state: {clusterName: clusterName, userDetails:userDetails, type:'C'}})
  }

  const handleClusterMessageClick = () => {
    updateClusterChatScreenData(true)
  }
  

  const handleHomeClick  = () => {
    navigate('/home', {state: {userDetails : userDetails}})
  }

const handleNotificationsClick = () => {
  navigate('/notifications', {state: {userDetails : userDetails}})
}

const handleClusterFeedClick = () => {
  navigate(`/cluster?cluster=${clusterName}`, {state : {user : userDetails}})
}

const handleLogoutClick = () => {
  navigate('/clusters')
}

  return (
    <>
      <IconButton
        ref={btnRef}
        colorScheme="yellow"
        _hover={{ bgColor: "black" }}
        onClick={onOpen}
        icon={<IoIosMenu />}
        variant="ghost"
        fontSize="2.5rem"
        fontStyle="bold"
        pt="10px"
        ml="10px"
      ></IconButton>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        
      >
        <DrawerOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(8px) hue-rotate(0deg)"
        />
        <DrawerContent
          bgColor="black"
          borderColor='grey'
          borderRadius="0px"
          borderWidth="0 1px 0 0"
          zIndex="10"
        >
          {/* <DrawerCloseButton color={colors.primary_dim}/> */}
          <DrawerHeader color='white'>
            <Center>
              {/* <VStack> */}
              <Heading size="md">{clusterName ? (clusterName) : ""}</Heading>
              
              {/* </VStack> */}
              <Spacer />
              {theme === 'D' && <IconButton
                icon={<FaSun />}
                variant="ghost"
                fontSize="1.3rem"
                _hover={{ bgColor: colors.textDark }}
                color={colors.textlight}
                onClick={() => setTheme('L')}
              />}

              {theme === 'L' && <IconButton
                icon={<IoMdMoon />}
                variant="ghost"
                fontSize="1.3rem"
                _hover={{ bgColor: colors.textDark }}
                color={colors.textlight}
                onClick={() => setTheme('D')}
              />}
            </Center>
            <Text fontSize="12px" color={colors.primary_dim} >@{userDetails ? (userDetails.userName) : ""}</Text>
          </DrawerHeader>

          <DrawerBody width="100">
            <Stack spacing="30px">
            <MenuButton button_icon_margin={2} Icon={<IoMdHome/>} fontSize='25px' text="Home" size="sl" type="link" onClick={handleHomeClick} ></MenuButton>
              
              
              <MenuButton button_icon_margin={2} Icon={<MdVideoStable/>} text="Feed" fontSize='25px' size="sl" type="link" onClick={handleClusterFeedClick} ></MenuButton>
              
              <MenuButton button_icon_margin={2} Icon={<FaMessage/>} fontSize='25px' text="Messages" size="sl" type="link" onClick={handleClusterMessageClick} ></MenuButton>
              
              <MenuButton button_icon_margin={2} Icon={<ImProfile/>} fontSize='25px' text="Profile" size="sl" type="link" onClick={handleProfileClick}></MenuButton>
              
              
              <MenuButton
              button_icon_margin={2}
                text="Notifications"
                size="sl"
                type="link"
                Icon={<IoMdNotifications/>} fontSize='25px'
                onClick={handleNotificationsClick}
              ></MenuButton>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="ghost"
              mr={3}
              _hover={{ bgColor: "black" }}
              onClick={onClose}
              color='white'
            >
              Close
            </Button>
            <Button
              // variant="outline"
              borderColor="black"
              bgColor={colors.primary}
              mr={3}
              _hover={{ bgColor: colors.primary, borderColor:colors.primary }}
              // onClick={handleLogoutClick}
              color='whitesmoke'
              
            >
              All Clusters
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
