import React from "react";
import { Stack, Divider, Box, Center, Input, VStack, Text, HStack, Heading, IconButton, Flex, Image } from "@chakra-ui/react";
import { FaUserFriends } from "react-icons/fa";
import { GrCluster } from "react-icons/gr";
import { CiCamera } from "react-icons/ci";
import { GoPencil } from "react-icons/go";

// User Imports
import { colors } from "../../globalStyles/colors";
import MenuButton from "../../Components/MenuButton";
import NavBar from "../Landing/NavBar";
import GeneralInputField from "../../Components/GeneralInputField";
import OnlineDot from "../../Components/OnlineDot";
import ListItemBox from "../../Components/ListItemBox";
import GeneralButton from "../../Components/GeneralButton";
import Entity from "../connections/friends/Entity";
import CreatePost from "../cluster/CreatePost";
import ProfileContainer from "./ProfileContainer";
import ClusterNav from "../cluster/ClusterNav";
import ProfileAttributeContainer from "./ProfileAttributeContainer";

export default function ProfileBar({ userDetails, cluster, type, profile }) {
  return (
    <Box
      bgColor="black"
      borderColor="grey"
      height="100%"
      width="100%"
      position="relative"
    >
      <HStack
        justifyContent="space-between"
        ml="5px"
        mr="10px"
        mt={2}
        position="absolute"
        top={0}
        left={0}
        width="100%"
        zIndex={2}
        // bgColor="black"
        p={2}
      >
        <Image src="../../logo-2-bg-remove.png" maxW="40px" />
        <Heading variant="md" color="white">
          Cluster
        </Heading>
        {type === "P" && <NavBar userDetails={userDetails} />}
        {type === "C" && <ClusterNav userDetails={userDetails} clusterName={cluster?.clusterName} />}
      </HStack>
      <Stack spacing={-5} zIndex={1}>
        <Box height='400px' mt={3} >
        <ProfileContainer userDetails={userDetails} cluster={cluster} type={type} profile={profile} />
        </Box>
        <Box
          backgroundColor="rgba(48, 48, 47, 0.2)"
          boxShadow="0px 5px 10px rgba(48, 48, 47, 0.5), 0px 5px 10px rgba(48, 48, 47, 0.5)"
          borderRadius="10px"
          width="60%"
          minHeight="calc(100% + 10px)"
          alignSelf="center"
          bgColor={colors.primary_dim}
        >
          <ProfileAttributeContainer profile={profile} type={type} />
          <Box mt={3}/>
        </Box>
        
      </Stack>
    </Box>
  );
}
