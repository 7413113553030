import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex,Text, Button, VStack, useToast  } from "@chakra-ui/react";

// user Imports
import MenuButton from "./MenuButton";
import { colors } from "../globalStyles/colors";

export default function CommentComponent({comment}){
    const [userDetails, setUserDetails] = useState(null)
    const toast = useToast()
    useEffect(() => {
        const fetchUserDetails = async () => {
           
                axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/fetchUserDetails/id`, {params : {commentedBy : comment?.commentedBy}})
            .then(res => {
                console.log(res)
                setUserDetails(res.data.userDetails)
            }).catch(err => {
                // toast({
                //     title: "Couldn't fetch comment",
                //     description: "Try again later",
                //     status: "error", // You can use any status like "success", "error", "warning", or "info"
                //     duration: 5000,
                //     isClosable: true,
                //     variant: "solid", // Use "solid" for a solid background
                //     // Add your custom background color
                //     // Example: backgroundColor: "your-color-hex-code"
                //     // backgroundColor: "your-color-hex-code",
                    
                //     colorScheme:'red',
                // });
            })
        }
        if(comment)
            fetchUserDetails()
    }, [comment])
    return <Flex >
    {userDetails && <Text color={colors.primary} fontSize="12" mr="10px" >@{userDetails?.userName}</Text>}
        
        {comment && <Text color='white'>{comment.text}</Text>}
        
    </Flex>
}