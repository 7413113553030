export const colors = {
    primary:'rgb(252, 182, 91)',
    primary_dim:'rgb(252, 188, 104)',
    bglight:"rgba(230, 224, 196, 0.9)", 
    bgDark:'black', 
    textlight:'rgb(229, 224, 177, 0.8)', 
    textDark:'black', 
    borderLight:'grey',
    success:'green', 
    failure:'red',
    loading:'yellow', 
    warning:'orange', 
    info:'blue', 
    notification:'blue',
    heading:'orange'
}