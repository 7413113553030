import React, { useState } from "react";
import {
  IconButton, useToast, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader,
  FormLabel, ModalFooter, FormControl, Input, ModalCloseButton
} from "@chakra-ui/react";
import { IoAddOutline } from "react-icons/io5";
import axios from "axios";

// user imports
import { colors } from "../../globalStyles/colors";

export default function CreateCluster({ userDetails, clusterName, create, isOpen, onOpen, onClose }) {
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const toast = useToast();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleCreate = async () => {
    if (create === 'C') {
      await createCluster();
    } else if (create === 'E') {
      await createEnclave();
    }
  };

  const createCluster = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/create/cluster`, { name: name, bio: bio, userDetails: userDetails });
      console.log(response);

        toast({
          title: "Yay!",
          description: "Cluster Created",
          status: "success",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'yellow',
        });
      
      
    } catch (err) {
      toast({
        title: "Some error occured",
        description: "cluster couldn't be created",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        colorScheme: 'red',
      });
    }finally{
      onClose();
    }
  };

  const createEnclave = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/enclaves/create/enclave`, { name: name, bio: bio, userDetails: userDetails, clusterName: clusterName })
      console.log(response.data);

        toast({
          title: "Yay!",
          description: "Enclave Created",
          status: "success",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'yellow',
        });
      
      
    } catch (err) {
      console.log(err);
      toast({
        title: "Some error occured",
        description: "Try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        colorScheme: 'red',
      });
    }finally{
      onClose();
    }
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bgColor="black" textColor='rgb(255, 250, 240)' borderWidth='1px' borderColor='grey'>
          <ModalHeader>{create === 'C' ? 'Create a Cluster' : create === 'E' ? `Create an Enclave in ${clusterName}` : null}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{create === 'C' ? 'Cluster Name' : create === 'E' ? 'Enclave Name' : null}</FormLabel>
              <Input borderColor="orange"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
            _placeholder={{ color: 'rgb(255, 250, 240)'}}
            bgColor="rgb(250, 171, 30)"
            _hover={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            _focus={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}} ref={initialRef} placeholder={create === 'C' ? 'Enter a unique name for your cluster' : create === 'E' ? 'Enter a unique name for your enclave' : null} value={name} onChange={e => setName(e.target.value)} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Purpose</FormLabel>
              <Input borderColor="orange"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
            _placeholder={{ color: 'rgb(255, 250, 240)'}}
            bgColor="rgb(250, 171, 30)"
            _hover={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            _focus={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}} placeholder={create === 'C' ? "Describe your clusters' motive" : create === 'E' ? "Describe your enclave's motive" : null} value={bio} onChange={e => setBio(e.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button borderColor="orange" bgColor="rgb(250, 171, 30)" textColor='rgb(255, 250, 240)'
            _hover={{backgroundColor:"orange"}}
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"  mr={3} color="rgb(229,224,177)"
            width="150px" onClick={handleCreate}>
              Create
            </Button>
            <Button bgColor="grey" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
