import React, { useState } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Divider,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  FormControl,
  ModalFooter,
  Input,
  useDisclosure,
  ModalContent,
  FormLabel,
  HStack,
  useToast
} from "@chakra-ui/react";

import { FallingLines } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

// File Imports
import GeneralButton from "../Components/GeneralButton";

export default function Password() {
  
  const navigate = useNavigate();
  const toast = useToast();

  const location = useLocation();

  

  const email = location.state?.email || "E"

  console.log(email + " in password")
  
  const [isSLoading, setSLoading] = useState(false);
  const [password, setPassword] = useState('');

  const handleSignIn = async() => {
    
    
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/method1/setup/user`;
    const data = {
      "email" : email,
      "password":password,
    }
    try{ 
      const response = await axios.post(url, data).then(
        res => {
            setSLoading(false)    
        console.log(res.data);

        
        
        toast({
            title: "Welcome aboard!",
            description: res.data.message,
            status: "success", // You can use any status like "success", "error", "warning", or "info"
            duration: 5000,
            isClosable: true,
            variant: "solid", // Use "solid" for a solid background
            // Add your custom background color
            // ackgroundColor: "your-color-hex-code",
            
            colorScheme:'yellow',
          });
        return res}
      ).catch(err => {
        setSLoading(false)    
        var error = err.response.data.message;
        console.log(error)
        toast({
          title: "Invalid User",
          description: error,
          status: "error", // You can use any status like "success", "error", "warning", or "info"
          duration: 5000,
          isClosable: true,
          variant: "solid", // Use "solid" for a solid background
          // Add your custom background color
          // ackgroundColor: "your-color-hex-code",
          
          colorScheme:'yellow',
        });
        return err;
      })
      
      if(response.status == 200)
      navigate('/register', {state:{email:email}})
      
    }
    catch(e){

    }
    //       // b
    console.log(password);
    
   
    // temporary navigation
    
    // setSLoading(false);
  }

  return (
    <Box bgColor="black" h="100vh" w="100vw">
      <Center h="100vh">
        <Box bgColor="black" p="10">
          {/* Your component content goes here */}
          <Flex direction="row" align="center" justify="center">
            <Stack textAlign="center" spacing={3}>
              <Heading size="4xl" color="rgb(229, 224, 177, 0.9)">
                Cluster
              </Heading>
              <Heading size="sm" color="wheat">
              Coordinate, Coalesce, Cultivate, <br></br>on Cluster!
              </Heading>
            </Stack>
            <Center height="150px" m="5">
              <Divider orientation="vertical" />
            </Center>
            <Stack textAlign="center" spacing={3}>
              <Heading size="sm" color="rgb(229, 224, 177, 0.9)" >
                Only one step to go. Welcome!
              </Heading>


                <FormControl>
                  <Input placeholder="enter your password" borderColor="rgb(229, 224, 177, 0.7)" color="white" focusBorderColor='rgb(229, 224, 177)' _placeholder={{opacity:0.5, color:'rgb(229, 224, 177)'}} type= "password" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                </FormControl>

                  <GeneralButton text="Sign In" size="sl" isLoading={isSLoading} spinner={<FallingLines
            color="rgb(229,224,177)"
            width="50"
            height="50"
            visible={true}
            opacity={1}
            ariaLabel="falling-circles-loading"
          />} onClick={
                    () => {
                      setSLoading(true);
                    handleSignIn()}}>
                  </GeneralButton>
            </Stack>
            
            
          </Flex>
          {/* <Box boxShadow='dark-lg'> */}
          
            {/* </Box> */}
        </Box>
      </Center>
    </Box>
  );
}
