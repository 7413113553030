import { Flex, Center, IconButton, Text, Spacer, Box, Heading,Image, VStack, HStack, Divider, Button } from "@chakra-ui/react";
import React from "react";
import { MdMessage } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { IoMdNotifications } from "react-icons/io";
import { FaMessage } from "react-icons/fa6";
import { MdVideoStable } from "react-icons/md";

// user Imports
import Enclave from "../Enclave";
import CreatePost from "../CreatePost";
import { colors } from "../../../globalStyles/colors";
import ClusterNav from "../ClusterNav";
import GeneralInputField from "../../../Components/GeneralInputField";
import Entity from "../../connections/friends/Entity";
import MenuButton from "../../../Components/MenuButton";

export default function EnclaveBar({userDetails, updateShowPostComponent, clusterName,enclaveName,updateEnclaveChatScreenData, updateClusterChatScreenData}){
  const handleTextPost = () => {
    updateShowPostComponent(true)
}

const handleClusterMessageClick = () => {
  updateClusterChatScreenData(true)
}

const handleEnclaveMessageClick = () => {
  updateEnclaveChatScreenData(true)
}


    return <>
        <Flex bgColor={colors.bgDark} height='100%' width='100%' flexDirection='column' borderWidth='0px' borderColor={colors.primary} mt='15px' >
        <HStack justifyContent='space-between' ml='5px' mr='10px' mt={2}>
          
          
          <Image src='../../logo-2-bg-remove.png' maxW='40px' />
          <VStack spacing={-1}>
            <Heading fontSize={25} color='white'> {enclaveName}</Heading>
            <HStack spacing={1}><Text fontSize={16} color={colors.primary_dim} fontStyle='italic'>en/: </Text> <Text fontSize={14} color={colors.primary_dim}>enclave</Text></HStack>
          </VStack>
         
          <ClusterNav userDetails={userDetails} clusterName={clusterName} updateClusterChatScreenData={updateClusterChatScreenData}/>
         
       </HStack> 
            
            <Box mt={5}/>

            <MenuButton button_icon_margin={2} Icon={<FaMessage/>} fontSize='20px' text="Texts" size="sl" type="link" onClick={handleEnclaveMessageClick}  ></MenuButton>
             

            <MenuButton button_icon_margin={2} Icon={<MdVideoStable/>} fontSize='20px' text="Posts" size="sl" type="link"  ></MenuButton>
      

            <MenuButton button_icon_margin={2} Icon={<ImProfile/>} fontSize='20px' text="About" size="sl" type="link"  ></MenuButton>
              
{/* 
            <MenuButton text="Posts" size="sl" type="link"  ></MenuButton>
              <Divider
                orientation="horizontal"
                borderColor={colors.primary_dim}
                width="100%"
                alignSelf="center"
              /> */}


            
            <Box mb='30px'></Box>
            <Enclave title="Enclave Members" userDetails={userDetails} enclaveName={enclaveName} clusterName={clusterName} />
            <Box mb='30px'></Box>
            <Center >
              <Button borderRadius='20px' width='250px' height='50px' fontSize='20px' color='whitesmoke' _hover={{bgColor:colors.primary}} bgColor={colors.primary_dim} onClick={() => handleTextPost()}>Post</Button>
            </Center>
            <Box mb='30px'></Box>
            
              
            
        </Flex>
    </>
}