import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  IconButton,
  HStack,
  VStack,
  Center,
  Divider,
  Spacer,
  useToast,
  Button
} from "@chakra-ui/react";

import { FaLocationArrow } from "react-icons/fa";
import { IoOpenOutline } from "react-icons/io5";
import { IoPersonAdd } from "react-icons/io5";

// user Imports
import { colors } from "../../globalStyles/colors";
import ListItemBox from "../../Components/ListItemBox";
import GeneralInputField from "../../Components/GeneralInputField";
import CreateCluster from "./CreateCluster";
import axios from "axios";

export default function ClusterMemberList({clusterName, userDetails, updateShowMemberProfile, type, enclaveName}) {
  const [members, setMembers] = useState([])
  const toast = useToast()

  // useEffect(() => {

  //   const fetchData = async () => {
  //     var url
  //     if(title === "Enclaves"){
  //      url = `${process.env.REACT_APP_SERVER_BASE_URL}/enclaves/get/cluster-enclaves`
  //     }
  //     else if(title === "Members"){
  //       url = `${process.env.REACT_APP_SERVER_BASE_URL}/cluster/get/allmembers`
  //     }
  //     try {
  //       const response = await axios.get(url, {params : {user : userDetails?.userName, clusterName:  clusterName}});
  //       console.log(response)
  //       setData(response.data);
  //       if(response.status === 200)
  //         setError(null)
  //     } catch (error) {
  //       setError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // },[userDetails]);


  useEffect(()=>{
    const fetchMembersRequests = async () => {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/get/allmember/requests`, {params : {cluster : clusterName}})

      console.log(response)
      if(response.status === 200)
        setMembers(...response.data)
    }
    const fetchClusterMembers = async () => {
      var url = `${process.env.REACT_APP_SERVER_BASE_URL}/cluster/get/allmembers`
          
          try {
            const response = await axios.get(url, {params : {user : userDetails?.userName, clusterName:  clusterName}});
            console.log(response)
            setMembers(response.data);
            // if(response.status === 200)
            //   // setError(null)
          } catch (error) {
            // setError(error);
          } finally {
            // setLoading(false);
          }
        };
    
    if(type === 'E')
      fetchClusterMembers()
    fetchMembersRequests();
  }, [])

  const handleClusterProfile = cluster => {
    console.log("in cluster")
    console.log(cluster)
    updateShowMemberProfile(cluster)
  }

  const handleAcceptMember = async member => {
    console.log(member)
    console.log(clusterName)
    const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/add/member`, {user : member, cluster : clusterName})
    .then(res => {
      toast({
        title: "Yay!",
        description: 'Member Added',
        status: "success", // You can use any status like "success", "error", "warning", or "info"
        duration: 5000,
        isClosable: true,
        variant: "solid", // Use "solid" for a solid background
        // Add your custom background color
        // Example: backgroundColor: "your-color-hex-code"
        // backgroundColor: "your-color-hex-code",
        
        colorScheme:'yellow',
      });
    }).catch(e => {
      toast({
        title: "Oops!",
        description: e.response.data.message,
        status: "error", // You can use any status like "success", "error", "warning", or "info"
        duration: 5000,
        isClosable: true,
        variant: "solid", // Use "solid" for a solid background
        // Add your custom background color
        // Example: backgroundColor: "your-color-hex-code"
        // backgroundColor: "your-color-hex-code",
        
        colorScheme:'red',
      });
    })
    console.log(response)
  }

  const joinMemberToEnclave = async member => {
    console.log(member)
    console.log(clusterName)
    const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/enclaves/add/member`, {member : member, clusterName : clusterName, enclaveName : enclaveName})
    .then(res => {
      toast({
        title: "Yay!",
        description: 'Member Added',
        status: "success", // You can use any status like "success", "error", "warning", or "info"
        duration: 5000,
        isClosable: true,
        variant: "solid", // Use "solid" for a solid background
        // Add your custom background color
        // Example: backgroundColor: "your-color-hex-code"
        // backgroundColor: "your-color-hex-code",
        
        colorScheme:'yellow',
      });
    }).catch(e => {
      toast({
        title: "Oops!",
        description: e.response.data.message,
        status: "error", // You can use any status like "success", "error", "warning", or "info"
        duration: 5000,
        isClosable: true,
        variant: "solid", // Use "solid" for a solid background
        // Add your custom background color
        // Example: backgroundColor: "your-color-hex-code"
        // backgroundColor: "your-color-hex-code",
        
        colorScheme:'red',
      });
    })
    console.log(response)
  }


  return (
    <>
      <Center>
        <Box
          borderColor='grey'
          borderRadius="20px"
          // borderWidth="0px 0px 0px 0px"
          height="400px"
          width="100%"
          bgColor='rgb(31, 30, 30, 0.5)'
          boxShadow={`0px 5px 10px rgb(31, 30, 30, 0.8)`}
        >
          <Box
            boxShadow={`0px 2px 4px ${colors.borderLight}`}
            borderRadius="20px"
            bgColor={colors.primary_dim}
          >
            <HStack>
              <Text
                fontSize="14"
                color={colors.textDark}
                p="5px"
                fontWeight="Bold"
              >
                {type === 'E'  ? clusterName +" Members" : "Member Requests" }
              </Text>
              <Spacer/>
             
              <CreateCluster userDetails={userDetails} />
              <IconButton
              icon={<FaLocationArrow />}
              _hover={{ bgColor: colors.primary }}
              variant="ghost"
              color={colors.textDark}
              fontSize="1rem"
             
            />
              
           
            </HStack>
          </Box>

          <VStack
            align="stretch"
            overflowY="auto"
            height="80%"
            sx={{
              "&::-webkit-scrollbar": {
                w: "2",
                bg: `grey.100`,
                h:'2'
              },
              "&::-webkit-scrollbar-track": {
                w: "6",
                bg: `grey.100`,
                h:'2'
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10",
                bg: `yellow.100`,
              },
            }}
          >
                          

            {type !== 'E' && members != null ? members.map((cluster, key)=>(
              <ListItemBox
              image={cluster?.profileImageUrl}
              text={cluster.firstName + " " + cluster.lastName}
              isOnline="N"
              textColor={colors.textDark}
              icon={ <Button _hover={{bgColor:'transparent', textDecoration:'none'}} variant='ghost' color='orange'  >accept</Button>}
              onClick={() => handleAcceptMember(cluster)}
            />
            )) : null}

            {type === 'E' && members != null ? members.map((clusterMember, key)=>(
              <ListItemBox
              image={clusterMember?.profileImageUrl}
              text={clusterMember.firstName + " " + clusterMember.lastName}
              isOnline="N"
              textColor={colors.textDark}
              icon={ <Button _hover={{bgColor:'transparent', textDecoration:'none'}} variant='ghost' color='orange'  >add</Button>}
              onClick={() => joinMemberToEnclave(clusterMember)}
            />
            )) : null}
            
          </VStack>
          
        </Box>
        
      </Center>
    </>
  );
}
