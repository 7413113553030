import React, {useState, useEffect} from "react";
import { Flex,Center, Heading, VStack, Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import openSocket from 'socket.io-client';
import axios from "axios";
import { BiSearch } from "react-icons/bi";
// user imports
// import ClusterBar from './ClusterBar'
import Header from "./Header";
import { colors } from "../../globalStyles/colors";
import Feed from "../Landing/Feed";
import ClusterBar from "./ClusterBar";
import ClusterMemberList from "./ClusterMemberList";
import SelfPosts from "../profile/SelfPosts";
import CreatePostComp from "../../Components/CreatePostComp";
import ChatScreen from "../chat/ChatScreen";
import GeneralInputField from "../../Components/GeneralInputField";

export default function Cluster(){
    const [showMemberProfile, setshowMemberProfile] = useState(null)
    const [socket, setSocket] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [showCreatePost, setShowPostComponent] = useState(false)
    const [clusterChatScreen, setClusterChatScreen] = useState(false);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const clusterName = queryParams.get('cluster');
    const userDetails = useLocation().state.user;

    const [cluster, setCluster] = useState(null);

  useEffect(() => {
    const fetchClusterDetails = async () => {
        if(clusterName)
            await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/getCluster/${clusterName}`).then(res => setCluster(res.data))
        .catch(err => console.log(err))
    }
    fetchClusterDetails()
  }, [clusterName])

    useEffect(() => {

        const io = openSocket(`${process.env.REACT_APP_SERVER_BASE_URL}`);
        console.log("Socket Object:", io);
    
        io.on("connect", () => {
          console.log("Connected to the server");
          setSocket(io);
        //   setIsLoading(false); // Set loading to false once connected
        });
    
        io.on("disconnect", () => {
          console.log("Disconnected from the server");
        });
    
        return () => {
          // Clean up the socket connection if needed
          io.disconnect();
        };
      }, []);

    const updateShowMemberProfile = cluster => {
        setshowMemberProfile(cluster)
    }

    const updateShowPostComponent = showPost => {
        setShowPostComponent(showPost)
    }

    const updateClusterChatScreenData = setChat => {
        setClusterChatScreen(setChat)
    }

    return <Flex width='100%' bgColor='black' minH="100vh" height='100%' flexDirection='row'>
            
            <Flex width="23%" flexDirection="column"  position="fixed" top="0" zIndex="1" height="100vh"  >
                <ClusterBar userDetails={userDetails} clusterName={clusterName} updateShowPostComponent={updateShowPostComponent} updateClusterChatScreenData={updateClusterChatScreenData} />
                {/* <Entity userDetails={userDetails} title="My Clusters"/> */}
            </Flex>

            <Flex ml="23%" width="70%" >
               {(!showCreatePost && !clusterChatScreen) && <Feed scope='C' clusterName={clusterName} userDetails={userDetails} />}
               {clusterChatScreen && socket && <ChatScreen size='xxl' localUser={userDetails} socket={socket} recepient={cluster} updateChatScreenData={updateClusterChatScreenData} type='C' />}
               {showCreatePost && <CreatePostComp userDetails={userDetails} scope='C' clusterName={clusterName} updateShowCreatePost={updateShowPostComponent} />}
             </Flex>
             <Flex width="25%" height="100vh" mr="10px" mt="20px" flexDirection="column" position="fixed" top="0" right="5" zIndex="1" >
                <ClusterMemberList clusterName={clusterName} userDetails={userDetails} updateShowMemberProfile={updateShowMemberProfile} />
                <Box mt={5}/>
                <GeneralInputField alignSelf='center' icon={<BiSearch color='whitesmoke' />} height='45px' placeholder="Search a friend, cluster, trending topics" width='90%'/> 
              <Box mt={2}/> 
            </Flex>
        </Flex>
    
}