import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Divider,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  FormControl,
  ModalFooter,
  Input,
  useDisclosure,
  ModalContent,
  FormLabel,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import openSocket from 'socket.io-client'

// user Imports
import "./styles.css";
import GeneralButton from "../../Components/GeneralButton";
import Signup from "../../authentication/Signup";
import { colors } from "../../globalStyles/colors";
export default function Home() {


  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [isLoaded, setLoad] = useState(false);
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null)

  const handleClick = () => {
    console.log("Button is Clicked");
    setLoad(true);
    navigate("/signup");
  };
  
 


  return (
    <Box bgColor="black" h="100vh">
      <Center h="100vh">
        <Box bgColor="black" p="10">
          {/* Your component content goes here */}
          <Flex direction="row" align="center" justify="center">
            <Stack textAlign="center" spacing={3}>
            <Image  src="../../LogoOrange-bg-remove.png"/>
              {/* <Heading size="4xl" color={colors.primary}>
                Cluster
              </Heading>
              <Heading size="sm" color="wheat">
                See What's happening around <br></br>the Globe!
              </Heading>
               */}
            </Stack>
            <Center height="100px" m="5">
              <Divider orientation="vertical" />
            </Center>
            <Stack textAlign="center" alignItems='center' spacing={3}>
            <Button borderRadius='15px' width='150px' variant='solid' bgColor='orange.300' _hover={{bgColor:colors.primary}} onClick={onOpen} color='whitesmoke'>Join Now</Button>
              <Text fontWeight='Bold' fontSize='14px' color={colors.primary}>
                See What's happening around <br></br>the Globe!
              </Text>
              
            </Stack>
            
          </Flex>
          {/* <Box boxShadow='dark-lg'> */}
          <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpen}
              onClose={onClose}
              isCentered
            >
              <ModalOverlay backdropFilter='blur(30px) hue-rotate(0deg)'  bg='blackAlpha.700'/>
              <ModalContent background="linear-gradient(270deg, rgb(255, 165, 82), rgb(255, 189, 69) 70%)" textColor='rgb(255, 250, 240)'>
                {/* <ModalHeader color="rgb(229,224,177)">Create your account</ModalHeader> */}
                <ModalCloseButton color='white'/>
                <ModalBody pb={6}>
                  
                </ModalBody>

                {/* <ModalFooter>
              <Button colorScheme='blue' mr={3}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>*/}
                <Signup />
              </ModalContent>
            </Modal>
            {/* </Box> */}
        </Box>
      </Center>
    </Box>
  );
}
