import React, {useState, useEffect} from "react";
import { Flex,Center, Heading, VStack, Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import openSocket from 'socket.io-client';
import axios from "axios";
// user imports
// import ClusterBar from './ClusterBar'
import Header from "../Header";
import { colors } from "../../../globalStyles/colors";
import Feed from "../../Landing/Feed";
import ClusterMemberList from "../ClusterMemberList";
import SelfPosts from "../../profile/SelfPosts";
import CreatePostComp from "../../../Components/CreatePostComp";
import ChatScreen from "../../chat/ChatScreen";
import EnclaveBar from "./EnclaveBar";

export default function EnclaveLanding(){
    const [showMemberProfile, setshowMemberProfile] = useState(null)
    const [socket, setSocket] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [showCreatePost, setShowPostComponent] = useState(false)
    const [clusterChatScreen, setClusterChatScreen] = useState(false);
    const [enclaveChatScreen, setEnclaveChatScreen] = useState(false);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const clusterName = queryParams.get('cluster');
    const enclaveName = queryParams.get('enclave');
    const userDetails = useLocation().state.user;

    const [cluster, setCluster] = useState(null);
    const [enclave, setEnclave] = useState(null);

    useEffect(() => {
      const fetchClusterDetails = async () => {
          if(clusterName)
              await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/getCluster/${clusterName}`).then(res => setCluster(res.data))
          .catch(err => console.log(err))
      }
      fetchClusterDetails()
    }, [clusterName])

  useEffect(() => {
    const fetchEnclaveDetails = async () => {
        console.log(enclaveName)
        console.log(clusterName)
        if(enclaveName)
            await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/enclaves/get/enclaveDetails/${enclaveName}`).then(res => setEnclave(res.data))
        .catch(err => console.log(err))
    }
    fetchEnclaveDetails()
  }, [enclaveName])

    useEffect(() => {

        const io = openSocket(`${process.env.REACT_APP_SERVER_BASE_URL}`);
        console.log("Socket Object:", io);
    
        io.on("connect", () => {
          console.log("Connected to the server");
          setSocket(io);
        //   setIsLoading(false); // Set loading to false once connected
        });
    
        io.on("disconnect", () => {
          console.log("Disconnected from the server");
        });
    
        return () => {
          // Clean up the socket connection if needed
          io.disconnect();
        };
      }, []);

    const updateShowMemberProfile = cluster => {
        setshowMemberProfile(cluster)
    }

    const updateShowPostComponent = showPost => {
        setShowPostComponent(showPost)
    }

    const updateClusterChatScreenData = setChat => {
        setClusterChatScreen(setChat)
    }

    const updateEnclaveChatScreenData = setChat => {
      setEnclaveChatScreen(setChat)
  }

    return enclave && (<Flex width='100%' bgColor='black' minH="100vh" height='100%' flexDirection='row'>
            
      <Flex width="22.5%" flexDirection="column"  position="fixed" top="0" zIndex="1" height="100vh"  >
          <EnclaveBar userDetails={userDetails} enclaveName={enclaveName} clusterName={clusterName} updateShowPostComponent={updateShowPostComponent} updateEnclaveChatScreenData={updateEnclaveChatScreenData} updateClusterChatScreenData={updateClusterChatScreenData} />
          {/* <Entity userDetails={userDetails} title="My Clusters"/> */}
      </Flex>

      <Flex flex="1" ml="22.5%" width="50%"  >

         
          {/* {showClusterProfile != null ? <ProfileComponent user={userDetails} profile={showClusterProfile} type='C' /> : null}
          
          
         */}
          {enclaveChatScreen && socket  && <ChatScreen size='xl' localUser={userDetails} socket={socket} clusterName={clusterName}  recepient={enclave} updateChatScreenData={updateEnclaveChatScreenData} type='E' />}

         {(!showCreatePost && !clusterChatScreen && !enclaveChatScreen) && <Feed scope='E' enclaveName={enclaveName} clusterName={clusterName} userDetails={userDetails} />}
         {clusterChatScreen && socket && <ChatScreen size='xl' localUser={userDetails} socket={socket} recepient={cluster} updateChatScreenData={updateClusterChatScreenData} type='C' />}
         {showCreatePost &&  <CreatePostComp userDetails={userDetails} scope='E' enclaveName={enclaveName} clusterName={clusterName} updateShowCreatePost={updateShowPostComponent} />}
       </Flex>
       {(!clusterChatScreen) &&<Flex width="25%" height="90vh" mr="10px" mt="20px" flexDirection="column" position="fixed" top="0" right="5" zIndex="1" justifyContent="space-between" >
            <ClusterMemberList type='E' enclaveName={enclaveName} clusterName={clusterName} userDetails={userDetails} updateShowMemberProfile={updateShowMemberProfile} /> 
      </Flex>}
  </Flex>)
    
}