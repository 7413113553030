import React from "react";
import { Stack, Divider, Box, Center, Image,Input, VStack, Text, HStack, Heading, IconButton, Flex, Button } from "@chakra-ui/react";
import { FaUserFriends } from "react-icons/fa";
import { GrCluster } from "react-icons/gr";
import { CiCamera } from "react-icons/ci";
import { GoPencil } from "react-icons/go";

// User Imports
import { colors } from "../../globalStyles/colors";
import MenuButton from "../../Components/MenuButton";
import NavBar from "./NavBar";
import GeneralInputField from "../../Components/GeneralInputField";
import OnlineDot from "../../Components/OnlineDot";
import ListItemBox from "../../Components/ListItemBox";
import GeneralButton from "../../Components/GeneralButton";
import Entity from "../connections/friends/Entity";
import CreatePost from "../cluster/CreatePost";

export default function CommandBar({socket, updateChatScreenData, userDetails, updateShowCreatePost, updateShowUserProfile}){

  console.log(typeof updateChatScreenData)
  
  const handleTextPost = () => {
    updateShowCreatePost(true)
  }

  const handleMediaPost = () => {
    
  }

    return <Box bgColor='rgb(8, 8, 8)' borderColor='grey'
      borderRadius='0px' borderWidth='0px 0.8px 0px 0px'
      minH='100vh' maxH='100vh' width='100%' 
      // position="fixed" // Set the position to fixed
      // top="2"          // Fixed at the top
      // left="40"         // Fixed at the left
      // overflowY="auto" // Enable vertical scrolling for the entire box
      >
        
        <HStack justifyContent='space-between' ml='5px' mr='10px' mt={2}>
          
          
           <Image src='../../logo-2-bg-remove.png' maxW='40px' />
           <Heading variant='md' color='white'>Cluster</Heading>
          
           <NavBar userDetails={userDetails} socket={socket} />
          
        </HStack>

        <Stack spacing='20px' mt='50px'>

          

          
            
            
            
            {/* <CreatePost/> */}
          <Entity title="Requests"  userDetails={userDetails} updateShowUserProfile={updateShowUserProfile} />

          <Entity title="My Friends" updateChatScreenData={updateChatScreenData} userDetails={userDetails} />
          
          <Entity title="My Clusters" userDetails={userDetails} />

          <Center >
              <Button borderRadius='20px' width='250px' height='50px' fontSize='20px' color='whitesmoke' _hover={{bgColor:colors.primary}} bgColor={colors.primary_dim} onClick={() => handleTextPost()}>Post</Button>
            </Center>
      </Stack> 
    </Box>
}