import React, {useEffect, useState} from "react";
import { Box, HStack, Heading, Spacer, Center, IconButton, Text,Flex, useToast } from "@chakra-ui/react";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { BsFillArrowDownLeftCircleFill } from "react-icons/bs";

// user imports
import { colors } from "../../globalStyles/colors";
import OnlineDot from "../../Components/OnlineDot";
import GeneralInputField from "../../Components/GeneralInputField";
import Messages from "./Messages";
import { encryption, decryption } from "../../enc_dec/Encryption";
import { BiSearch } from "react-icons/bi";
import { FaCamera } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaVideo } from "react-icons/fa";

export default function ChatScreen({recepient, socket, localUser, updateChatScreenData, type, size, clusterName}){
    const [recepientUser, setRecepientUser] = useState(null);
    const [sentMessage, setSentMessage] = useState('')
    const [message, setMsg] = useState('')
    const toast = useToast()

    const chatScreenStyle ={
        sm: {
            // width: '20%',
            height: '500px',
            // fontSize: '14px',
          },
        md: {
            width: '100%',
            height: '500px',
            // fontSize: '18px',
          },
        lg: {
            // width: '40%',
            height: '600px',
            // fontSize: '20px',
        },
        xl:{
        width:'50%',
        height:'95vh',
        // fontSize:'20px'
        },
        xxl:{
            width:'65%',
            height:'95vh',
        }
        // c:{
        // width:width,
        // fontSize:fontSize,
        // height:height
        // }
    }

    const screenStyles = {
        width : chatScreenStyle[size].width || chatScreenStyle.sm.width, 
        height: chatScreenStyle[size].height || chatScreenStyle.sm.height, 
    }
  
    useEffect(() => {
      // Update the local state whenever the user prop changes
      console.log(recepient)
      setRecepientUser(recepient);
    }, [recepient]);
  

    const handleClose = () => {
        
        setRecepientUser(null)
        console.log("recepient")
        console.log(recepient)

        updateChatScreenData(null)
        
      };

    const connectAudioCall = () => {
        toast({
            // title: "Coming Soon!",
            description: "Audio Call is Coming Soon!",
            status: "info",
            duration: 5000,
            isClosable: true,
            variant: "solid",
            position:'top-left'
            // colorScheme: 'yellow',
          })
    }

    const connectVideoCall = () => {
        toast({
            // title: "Coming Soon!",
            description: "Video Call is Coming Soon!",
            status: "info",
            duration: 5000,
            isClosable: true,
            variant: "solid",
            position:'top-left'
            // colorScheme: 'yellow',
          })
    }
      
    if (recepientUser === null) return <></>;

    // if(user!=null){
    // console.log("in chatScreenData " + user.email)
    // console.log("in chatScreenData " +socket)
    // }

    const sendMessage = text => {
        var encryptedText = encryption(text, `${localUser.userName}to${recepientUser.userName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
        socket.emit('message', {type:'private', scope: 'private', recepient: recepientUser.userName, sender: localUser.userName, message:encryptedText, timeStamp:Date.now()}   )
    }

    const broadCastClusterMessage = text => {
        console.log(`${recepientUser.clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
        var encryptedText = encryption(text, `${recepientUser.clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
        socket.emit('message', {type:'private', scope: 'cluster', recepient: recepientUser.clusterName, sender: localUser.userName, message:encryptedText, timeStamp:Date.now(), senderProfileImage : localUser.profileImageUrl}   )
    }

    const broadCastEnclaveMessage = text => {
        if(!clusterName) return;
        console.log(`${recepientUser.enclaveName}in${clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
        var encryptedText = encryption(text, `${recepientUser.enclaveName}in${clusterName}by${process.env.REACT_APP_CLUSTER_ENCRYPTION_KEY}`)
        socket.emit('message', {type:'private', scope: 'enclave',superScope: clusterName, recepient: recepientUser.enclaveName, sender: localUser.userName, message:encryptedText, timeStamp:Date.now(), senderProfileImage : localUser.profileImageUrl}   )
    }

    const handleSend = () => {
        // setSentMessage(message)
        if(message.length!=0 && type==='P')
            sendMessage(message)
        if(message.length!=0 && type==='C')
            broadCastClusterMessage(message)
        if(message.length!=0 && type==='E')
            broadCastEnclaveMessage(message)

        setMsg('')
    }

   
    
    return <>
            <Flex width='100%'  bgColor='rgb(31, 30, 30, 0.5)'
          boxShadow={`0px 2px 2px grey`} mt='20px' flexDirection='column' {...screenStyles} borderColor='grey' borderWidth='0px 1px 1px 1px' borderRadius='10px'>
                {/* <Flex flex='1'> */}
                    <Box  width='100%' height='7%' borderWidth='2px' bgColor={colors.primary} borderColor={colors.primary} borderRadius='10px'>
                        <HStack height='100%'>
                            {type==='P' && <Text ml='10px' fontSize='18px' fontWeight='Bold' color='white'>{recepientUser != null ? recepientUser.userName : "Dummmy"}</Text>}
                            {type==='C' && (<><Text  ml='10px' fontWeight='Bold' color='white' >cr/:</Text><Text fontSize='18px' fontWeight='Bold' color='white'>{recepientUser != null ? recepientUser.clusterName : "Dummmy"}</Text></>)}
                            {type==='E' && (<><Text  ml='10px' fontWeight='Bold' color='white' >en/:</Text><Text  fontSize='18px' fontWeight='Bold' color='white'>{recepientUser != null ? recepientUser.enclaveName : "Dummmy"}</Text></>)}
                            {recepientUser && <OnlineDot/>}
                            <Spacer/>
                            <IconButton icon={<FaVideo  />} onClick={() => connectVideoCall()}  justifyItems='flex-end' width='20px' height='20px' mt='6px' fontSize='1.5rem'_hover={{bgColor:colors.primary}} title={type === 'P' ? "Video Call" : "Group Video Call"} variant='ghost' color='white'  />
                            <IconButton icon={<IoIosCall  />} onClick={() => connectAudioCall()}  justifyItems='flex-end' width='20px' height='20px' mr='1px' mt='6px' fontSize='1.5rem'_hover={{bgColor:colors.primary}} title={type === 'P' ? "Audio Call" : "Group Audio Call"} variant='ghost' color='white'  />
                            <IconButton icon={<BsFillArrowDownLeftCircleFill  />} onClick={handleClose}  justifyItems='flex-end' width='20px' height='20px' mt='6px' fontSize='1.5rem'_hover={{bgColor:colors.primary}} title="Close" variant='ghost' color='white'  />
                        </HStack>
                     </Box>
                {/* </Flex> */}
                
                <Flex flex='5' height='70%' width='100' overflow="auto" >
                    <Messages clusterName={clusterName} socket={socket} localUser={localUser} recipient={recepientUser} type={type} />
                </Flex>

                <Flex flex='1' justifyContent='center' width='100%' ml={2}>
                        <HStack width='100%' spacing={-1}>
                            <GeneralInputField
                            icon={<FaCamera color="orange"/>}
                            placeholder='Type a message' width='95%' 
                            value={message}
                            onChange={event => setMsg(event.target.value)}
                            height='40px'
                            borderRadius='15px'
                            ></GeneralInputField>
                            <IconButton   _hover={{bgColor:'rgb(31, 30, 30, 0.5)', color:'green.300'}} icon={<BsArrowRightCircleFill />} width='5%'  mr='6px' fontSize='2rem' mb='5px' title="Send Message" variant='link' color={colors.primary} onClick={handleSend} />
                        </HStack>      
                </Flex> 
            </Flex>
    </>
}