import { HStack, Image, Text, Box, IconButton, Spacer, Avatar } from "@chakra-ui/react";
import React from "react";
import { MdMessage } from "react-icons/md";

import OnlineDot from "./OnlineDot";
import { colors } from "../globalStyles/colors";
import MenuButton from "./MenuButton";

export default function ListItemBox({ isOnline, image, text, textColor, onClick, icon, onTextClick , bgColor}) {
  return (
    <HStack width='100%' height='50px' borderColor='grey' borderWidth='0px 0px 0px 0px' justifyContent='space-between'>
      <Box width='30px' height='30px' borderRadius='100px' m='5px 0px 0px 5px'>
       <Avatar size='15px' src={image}/>
      </Box>
      <Box overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis" width='50%'>
      {/* <Text fontSize='12'  color={textColor || colors.primary}>
        {text}
      </Text> */}
      <MenuButton button_icon_margin={0} text={text} size="sm" onClick={onTextClick} bgColor={bgColor} />
      </Box>
      {isOnline === 'Y' && <OnlineDot textColor={textColor} />}
      <Spacer />
      <IconButton
        variant='link'
        icon={icon ||  <MdMessage color={textColor || colors.primary}/>}
        
        ml='5px' // Adjust margin-left as needed
        onClick={onClick}
      />
    </HStack>
  );
}
