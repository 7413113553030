import React, { useState, useEffect, useRef } from 'react';
import { VStack, Box, Flex, Heading, Center } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';

import PostComponent from '../../Components/PostComponent';
import CommentScreenComponent from '../../Components/CommentScreenComponent';
import DummyComponent from '../../Components/DummyComponent';
import { colors } from '../../globalStyles/colors';
import LogoSpinner from '../../animations/LogoSpinner';


const Feed = ({ socket, scope, userDetails, clusterName, enclaveName }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const newPostRef = useRef(null);
  const [commentData, setCommentData] = useState(null)

  const setCommentScreenData = post => {
    setCommentData(post)
  }
  
  useEffect(() => {
    const fetchGlobalFeed = async () => {
      try {
        console.log("scope " + scope)
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/global/get/posts`, {params:{scope:scope}});
        const newData = response.data;

        if (newData.length > 0) {
          setData((prevData) => [...newData, ...prevData]);
          setPage((prevPage) => prevPage + 1);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if(scope === 'G' || scope==='C')
      fetchGlobalFeed();
  }, []); // Run once on component mount

  useEffect(() => {
    const fetchPersonalPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/personal/get/posts`, {params : {userDetails:userDetails}});
        const newData = response.data;

        if (newData.length > 0) {
          setData((prevData) => [...newData, ...prevData]);
          setPage((prevPage) => prevPage + 1);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if(scope === 'P')
      fetchPersonalPosts();
  }, []); 

  useEffect(() => {
    const fetchClusterPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/cluster/get/posts`, {params : {clusterName : clusterName}});
        const newData = response.data;

        if (newData.length > 0) {
          setData((prevData) => [...newData]);
          setPage((prevPage) => prevPage + 1);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if(scope === 'C')
      fetchClusterPosts();
  }, []); 

  useEffect(() => {
    const fetchEnclavePosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/enclave/get/posts`, {params : {clusterName : clusterName, enclaveName : enclaveName}});
        const newData = response.data;

        if (newData.length > 0) {
          setData((prevData) => [...newData]);
          setPage((prevPage) => prevPage + 1);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if(scope === 'E')
      fetchEnclavePosts();
  }, []); 

  useEffect(() => {
    const showNewpost = (post) => {
      if (post.action === 'newpost') {
        console.log("in post");
        console.log(post.post);
        newPostRef.current = post.post;
      }
    };
    
    if(scope === 'G')
      socket.on('global', showNewpost);

    return () => {
      // Clean up the socket event listener when the component unmounts
      if(scope==='G')
      socket.off('global', showNewpost);
    };
  }, [socket]);

  useEffect(() => {
    // Check if newPostRef has a value and update the state
    if (newPostRef.current) {
      setData((prevData) => [newPostRef.current, ...prevData]);
      // Reset the newPostRef to null after updating the state
      newPostRef.current = null;
    }
  }, [newPostRef.current]);

  if(commentData!=null){
    return <Flex width='100%'>
     <CommentScreenComponent userDetails={userDetails} setCommentScreenData={setCommentScreenData} post={commentData} isCommentScreen = {true} />
     </Flex>
  }

  if(data.length==0)
    return <Box height='100vh'  display='flex' width='50%' bgColor={colors.bgDark} alignItems='center' justifyContent='center'>
  <LogoSpinner width='100px' />
  </Box>

  // if(data.length==0)
  //   return <Flex width="100%" alignContent="center"><Center><Heading color={colors.primary}>Posts will show up here</Heading></Center></Flex>

  return (
    <VStack align="stretch">
      <InfiniteScroll
        dataLength={data.length}
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        {data.map((item, index) => (
          <Box key={index}>
            <PostComponent userDetails={userDetails} post={item} isCommentScreen={false} setCommentScreenData={setCommentScreenData}/>
          </Box>
        ))}
      </InfiniteScroll>
    </VStack>
  );
};

export default Feed;
