import React, { useEffect, useState } from "react";
import { Center, Box, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import axios from "axios";
import { FaUserFriends } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";

// user imports
import { colors } from "../../../globalStyles/colors";
import ListItemBox from "../../../Components/ListItemBox";

const useFetchData = (userDetails) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(userDetails);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_SERVER_BASE_URL}/connections/get/allusers`;
      try {
        const response = await axios.get(url, { params: { user: userDetails?.userName } });
        console.log(response);
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userDetails]);

  return { data, loading, error };
};

export default function UserList({ userDetails, updateShowUserProfile }) {
  const toast = useToast();
  const { data: userList, loading, error } = useFetchData(userDetails);

  const handleRequestFollow = async (user) => {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_SERVER_BASE_URL}/connections/request/connection`, {
          user1: userDetails,
          user2: user,
        })
        .then((res) => {
          toast({
            title: "Yay!",
            description: res.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
            variant: "solid",
            colorScheme: "yellow",
          });
        })
        .catch((e) => {
          toast({
            title: "Oops!",
            description: e.response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            variant: "solid",
            colorScheme: "red",
          });
        });
      if (response.status === 200) {
      }
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  const handleShowProfile = (user) => {
    updateShowUserProfile(user);
  };

  return (
    <Center>
       <Box
          borderColor='grey'
          borderRadius="20px"
          // borderWidth="0px 0px 0px 0px"
          height="400px"
          width="100%"
          bgColor='rgb(31, 30, 30, 0.5)'
          boxShadow={`0px 5px 10px rgb(31, 30, 30, 0.8)`}
        >
          <Box
            boxShadow={`0px 2px 4px ${colors.borderLight}`}
            borderRadius="20px"
            bgColor={colors.primary_dim}
          >
          <HStack>
            <Text fontSize="14px" fontWeight='Bold' color='white' p="5px">
              Add a friend
            </Text>
            <FaUserFriends color='white' p="5px" />
          </HStack>
        </Box>

        <VStack
          align="stretch"
          overflowY="auto"
          height="90%"
          sx={{
            "&::-webkit-scrollbar": {
              w: "2",
              // bg: `yellow.100`,
            },
            "&::-webkit-scrollbar-track": {
              w: "6",
              // bg: `yellow.100`,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10",
              // bg: `yellow.100`,
            },
          }}
        >
            {userList &&
              userList.map((user, index) => (
                <ListItemBox
                image = {user?.getUrl}
                  key={index}
                  text={user.userName}
                  bgColor={colors.bglight}
              isOnline="N"
              textColor={colors.textDark}
                  onClick={() => handleRequestFollow(user)}
                  icon={<IoMdPersonAdd color={colors.primary} />}
                  onTextClick={() => handleShowProfile(user)}
                />
              ))}
          </VStack>
        {/* </Box> */}
      </Box>
    </Center>
  );
}
