import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChakraProvider, Box, Heading, Text, Divider, Button } from "@chakra-ui/react";
import TermsOfUse from "./TermsOfUse";

function Notice() {
  const navigate = useNavigate()
  const userDetails = useLocation().state?.userDetails

  return (
    <ChakraProvider>
      <Box bg="black" color="white" minHeight="100vh" p={8}>
        <Heading as="h1" fontSize="3xl" mb={6}>
          Cluster
        </Heading>

        <Section heading="Upcoming Version">
          <Text>
            Beta 1.1.0 
          </Text>
        </Section>

        


        <Section heading="Upcoming Features:">

        <SectionSubheading heading="General Features">
  <Text>
    <ol>
      <li>Users can interact by replying to comments, facilitating engaging discussions.</li>
      <li>Utilize tags to categorize posts, enhancing their visibility and reach within the community.</li>
      <li>Share functionality enabled, allowing users to effortlessly distribute content across various platforms.</li>
      <li>Enjoy a sleek and user-friendly light theme for a visually pleasing experience.</li>
    </ol>
  </Text>
</SectionSubheading>
        <SectionSubheading heading="User-Specific Features">
  <Text>
    <ol>
      <li>Users will have the capability to explore the platform comprehensively, conducting searches for users, clusters, or trending topics.</li>
      <li>Users can seamlessly create captivating visual content, including images and videos, directly within the application.</li>
      <li>Enhanced interface for managing requests, friends, and personal clusters, offering a more intuitive user experience.</li>
      <li>Users will receive timely notifications, ensuring they stay informed and engaged with the platform.</li>
      <li>Real-time status indicators will allow users to easily identify the online presence of other users.</li>
    </ol>
  </Text>
</SectionSubheading>


<SectionSubheading heading="Cluster Features">
  <Text>
    <ol>
      <li>Create private clusters for exclusive discussions and collaboration.</li>
      <li>Enable follower functionality to stay updated on cluster activities and discussions.</li>
      <li>Grant privileges to cluster members, allowing for customized access and participation levels.</li>
      <li>Control the visibility of posts within clusters to manage content dissemination.</li>
      <li>Facilitate efficient navigation with a search feature tailored specifically for clusters.</li>
      <li>Remove members from clusters as needed to maintain community integrity.</li>
      <li>Receive live notifications for cluster activities, ensuring timely engagement.</li>
    </ol>
  </Text>
</SectionSubheading>


<SectionSubheading heading="Enclave Specific Features">
  <Text>
    <ol>
      <li>Initiate interaction by adding a 'Join' button for cluster members to seamlessly join enclaves.</li>
      <li>Foster communication and collaboration within enclaves by enabling messaging functionality.</li>
      <li>Empower enclave creators with the ability to modify the enclave name to better reflect its purpose or focus.</li>
      <li>Enhance content diversity and engagement by allowing the creation of video posts within enclaves.</li>
      <li>Grant special privileges to enclave authors, enabling them to manage and curate enclave content effectively.</li>
    </ol>
  </Text>
</SectionSubheading>

<Text>
    We value your feedback! If there are specific features you'd like to see in Cluster, please let us know by 
    <Button variant="link" onClick={() => navigate('/feedback', { state: { userDetails: userDetails } })}>&nbsp;
       clicking here
    </Button>
    .
  </Text>

  <Text>
    The following features are expected to arrive in upcoming versions, although some may be included in the final release.
  </Text>

        </Section>
        <Section heading="Security">
          <Text>
            At Cluster, we prioritize the security and privacy of your data. Here's how we ensure your information remains safe:
          </Text>
          <ul>
            <li><strong>End-to-End Encryption:</strong> All messages sent through the Cluster application, whether person-to-person or within a group/cluster, are encrypted using 256-bit encryption. This means that your communications are protected from unauthorized access, ensuring that only you and the intended recipients can decrypt and view the contents of your messages.</li>
            <li><strong>JWT Token and Google's Secure Authentication:</strong> We employ industry-standard security protocols, including JWT tokens and Google's secure authentication, to verify and authenticate users. These measures help safeguard against unauthorized access to your account and ensure that only authorized individuals can access the application.</li>
            <li><strong>No Message Reading:</strong> It's important to note that we respect your privacy. We do not read or access your messages. Our end-to-end encryption ensures that your communications remain confidential, and we do not have the ability to decrypt or view your messages.</li>
          </ul>
          <Text>Click here to read <Button variant='link' onClick={() => navigate('/termsOfuse')}>Terms of use</Button></Text>
        </Section>
        

        {/* <Section heading="Features" id="upcoming">

        </Section> */}
      </Box>
    </ChakraProvider>
  );
}

function Section({ heading, children }) {
  return (
    <Box mb={6}>
      <Heading as="h2" fontSize="2xl" mb={4} mt={6}>
        {heading}
      </Heading>
      {children}
      <Divider borderColor="white" />
    </Box>
  );
}

function SectionSubheading({ heading, children }) {
  return (
    <Box mb={6}>
      <Heading as="h3" fontSize="xl" mb={4}>
        {heading}
      </Heading>
      {children}
    </Box>
  );
}

export default Notice;
