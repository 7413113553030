import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import { useToast,VStack, Box, Heading, HStack, Center, Text } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BsArrowRightCircleFill } from "react-icons/bs";
import { IconButton } from '@chakra-ui/react';
import LogoSpinner from '../animations/LogoSpinner';

// user Imports
import PostComponent from './PostComponent';
import CommentComponent from './CommentComponent';
import MenuButton from './MenuButton';
import GeneralInputField from './GeneralInputField';
import { colors } from '../globalStyles/colors';

export default function CommentScreenComponent({post,setCommentScreenData, userDetails}){
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const newPostRef = useRef(null);
    const [comments, setComments] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [comment, setComment] = useState('')
    const [key, setKey] = useState(1)
    const toast = useToast();

    useEffect(() => {
        const fetchComments = async () => {
            setComment('')
            await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/fetch/post-comments`, {params: {postId : post?._id, userDetailsId : userDetails?._id}})
            .then(res => {
                console.log(res)
                res.data.length > 0 && setComments(res.data)
            }).catch(err => {
                toast({
                    title: "Try again later",
                    description: "Coudln't fetch comments right now.",
                    status: "error", // You can use any status like "success", "error", "warning", or "info"
                    duration: 1000,
                    isClosable: true,
                    variant: "solid", // Use "solid" for a solid background
                    // Add your custom background color
                    // Example: backgroundColor: "your-color-hex-code"
                    // backgroundColor: "your-color-hex-code",
                    
                    colorScheme:'red',
                });
            })
            setLoading(false)
        }
        fetchComments()
    }, [key])

    const handleCreateComment = async (post, comment) =>{
        await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/media/create/comment`, {postId : post?._id, comment:comment, userDetailsId:userDetails?._id})
        .then(res => {
            console.log(res)
            
            setKey(prevKey => prevKey + 1)
            
            toast({
                title: "Comment Added",
                description: "",
                status: "success", // You can use any status like "success", "error", "warning", or "info"
                duration: 5000,
                isClosable: true,
                variant: "solid", // Use "solid" for a solid background
                // Add your custom background color
                // Example: backgroundColor: "your-color-hex-code"
                // backgroundColor: "your-color-hex-code",
                
                colorScheme:'yellow',
            });
        }).catch(err => {
            console.log(err)
            toast({
                title: "Couldn't add comment",
                description:err.response.data.message,
                status: "error", // You can use any status like "success", "error", "warning", or "info"
                duration: 5000,
                isClosable: true,
                variant: "solid", // Use "solid" for a solid background
                // Add your custom background color
                // Example: backgroundColor: "your-color-hex-code"
                // backgroundColor: "your-color-hex-code",
                
                colorScheme:'red',
            });
        })
    }
    // if(isLoading) 
    //     return (
    // <></>
    //     //   <Box display='flex' bgColor={colors.bgDark} alignItems='center' justifyContent='center'>
    //     //       <LogoSpinner width='100px' />
    //     //   </Box>
    //     )
    return <>
     <VStack  align="stretch">
        <PostComponent post={post} isCommentScreen={true} setCommentScreenData={setCommentScreenData}/>
        <HStack>
            <GeneralInputField ml='5px' height='40px' placeholder="Add a comment.." width="90%" value={comment} onChange={event => setComment(event.target.value)} />  
            <IconButton icon={<BsArrowRightCircleFill />} width='5%'  mr='5px' fontSize='2rem' mb='5px' _hover={{bgColor:'black'}} title="Post" variant='ghost' color={colors.primary} onClick={() =>handleCreateComment(post, comment)} />  
        </HStack>   
        {!comments && <Box display='flex' width='100%' bgColor={colors.bgDark} alignItems='center' justifyContent='center'>
              <LogoSpinner width='25px' />
              <Text color='grey' fontSize='12px'> Loading comments...</Text>
          </Box>}    
        {comments && <InfiniteScroll
            dataLength={data.length}
            next={() => setPage(page + 1)}
            hasMore={hasMore}
            loader={<Box display='flex' width='100%' bgColor={colors.bgDark} alignItems='center' justifyContent='center'>
            <LogoSpinner width='25px' />
        </Box>}
        >
            {comments.map((comment, index) => (
            <Box key={index} p={4} width='100%'>
                <CommentComponent comment={comment}/>
                <Center><MenuButton text="Reply" size="sm"/></Center>
            </Box>
            ))}
      </InfiniteScroll>}

      {!comments &&  <CommentComponent message="No comments to show"/>}
    </VStack>
    </>
}