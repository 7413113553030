import { BrowserRouter as Router, Routes, Route,useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
// import { ThemeProvider} from "styled-components";
import { GlobalStyle } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import React, { useState, useEffect } from "react";
// import logo from './logo.svg';

import './App.css';
import Home from './views/home/Home';
import GalaxyCluster from "./Components/GalaxyCluster";
import AuthModal from "./authentication/AuthModal";
import Login from "./authentication/Login";
import Landing from "./views/Landing/Landing";
import Password from "./authentication/Password";
import RegisterUser from "./authentication/RegisterUser";
import ProfileHome from "./views/profile/ProfileHome";
import ClusterLand from "./views/cluster/ClusterLand";
import Cluster from "./views/cluster/Cluster";
import CommentComponent from "./Components/CommentComponent";
import Feedback from "./feedBack/Feedback";
import EnclaveLanding from "./views/cluster/enclaves/EnclaveLanding";
import AboutPage from "./views/Landing/AboutPage";
import TermsOfUse from "./views/Landing/TermsOfUse";
import Notice from "./views/Landing/Notice";
import { validateToken } from './util/auth'; 
import ResetPassword from "./authentication/ResetPassword";
import LogoSpinner from "./animations/LogoSpinner";
import Privacy from "./views/Landing/Privacy";


function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['essential', 'payload']);
  const [isAuthenticated, setIsAuthenticated] = useState(true)

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     const token = `${cookies.essential}.${cookies.payload}`;
  //     const isValid = await validateToken(token);
  //     setIsAuthenticated(isValid);
  //   };

  //   checkAuth();
  // }, [cookies]);

  return (
    <ChakraProvider>
      <React.Fragment>
        <Router>
          <Routes>
            <Route path="/" element={<Home/>}/>
            {/* <Route path="/galaxy" element={<GalaxyCluster/>}/> */}
            <Route path="/signup" element={<AuthModal/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/home" element={<Landing/>}/>
            <Route path="/signup/password" element={<Password/>}/>
            <Route path="/register" element={<RegisterUser/>}/>
            <Route path='/profile' element={<ProfileHome/>}/>
            <Route path="/clusters" element={<ClusterLand/>}/>
            <Route path="/cluster"  element={<Cluster/>}/>
            <Route path="/enclave"  element={<EnclaveLanding/>}/>
            <Route path="/comment" element={<CommentComponent message={"Some randome comment section over here."}/>}/>
            <Route path="/feedback" element={<Feedback/>}/>
            <Route path="/about" element={<AboutPage/>} />
            <Route path='/termsOfuse' element={<TermsOfUse/>}/>
            <Route path="/upcoming-version" element={<Notice/>}/>
            <Route path="/resetpassword" element={<ResetPassword/>}/>
            <Route path="/resetpassword/:token" element={<ResetPassword/>} />
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path='/logo' element={<LogoSpinner/>}/>
          </Routes> 
        </Router>
      </React.Fragment>
    </ChakraProvider>
  );
}

export default App;
