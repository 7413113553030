import React from "react";
import { keyframes } from "@emotion/react";
import { Box } from "@chakra-ui/react";

import { colors } from "../globalStyles/colors";

// Define keyframes
const growShrinkAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export default function OnlineDot({textColor}) {
  return (
    <Box
      bgColor={textColor || colors.primary}
      borderRadius="100%"
      width="8px"
      height="8px"
      animation={`${growShrinkAnimation} 2s infinite alternate`}
    ></Box>
  );
}
