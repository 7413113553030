import React from "react";
import { Center, Heading, Flex, VStack,Text, Box } from "@chakra-ui/react";


import { colors } from "../../globalStyles/colors";

export default function ProfileAttributeContainer({profile, type, ...rest}){
    console.log(profile)
    return <>
    {type === 'P' ?  ( 
            <VStack mt={3} spacing={-1} >
                <Heading size="md" color='white' >{profile.firstName + " " + profile.lastName}</Heading>
                <Text fontSize={12} color='white' >@{profile.userName}</Text>
            </VStack>) : null}
        {type === 'P' ? <Flex flexDirection="row" justifyContent="space-evenly">
            <VStack spacing={-1}>
                <Center mt="10px"><Heading size="md" color='white'>{profile?.friends || 0}</Heading></Center>
                <Center><Heading size="sm" color='white' >Connections</Heading></Center>
            </VStack>
            <VStack spacing={-1}>
                <Center mt="10px"><Heading size="md" color='white' >{profile?.followers || 0}</Heading></Center>
                <Center><Heading size="sm" color='white' >Followers</Heading></Center>
            </VStack>
            <VStack spacing={-1}>
                <Center mt="10px"><Heading size="md" color='white' >{profile?.following || 0}</Heading></Center>
                <Center><Heading size="sm" color='white' >Following</Heading></Center>
            </VStack>
        </Flex> : null}
       {type === 'C' ? (
            <VStack mt={3} spacing={1} >
                <Heading size="md" color='white' >{profile?.clusterName}</Heading>
                <Heading size="sm" color='white' >{profile?.bio}</Heading>
            </VStack>
        ) : null}
        {type=== 'C' ?<Flex flexDirection="row" justifyContent="space-evenly">
        
            <VStack spacing={-1}>
                <Center mt="10px"><Heading size="md" color='white' >{profile?.noMembers}</Heading></Center>
                <Center><Heading size="sm" color='white' >Members</Heading></Center>
            </VStack>
            <VStack spacing={-1}>
                <Center mt="10px"><Heading size="md" color='white' >{profile?.noPosts}</Heading></Center>
                <Center><Heading size="sm" color='white' >Posts</Heading></Center>
            </VStack>
            <VStack spacing={-1}>
                <Center mt="10px"><Heading size="md" color='white' >{profile?.noEnclaves}</Heading></Center>
                <Center><Heading size="sm" color='white' >Enclaves</Heading></Center>
            </VStack>
           
        </Flex> : null} 
    </>
}