import React from 'react';
import { Box, Button,Center, HStack, useToast} from '@chakra-ui/react';
import { colors } from '../../globalStyles/colors';
import axios from 'axios';

export default function ProfileButtonsContainer({user, profile, type}){
    const toast = useToast()

    const handleRequestMember = async () => {
        try{
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/request/membership`, {user : user, cluster : profile.clusterName}) 
            .then(res => {
                toast({
                  title: "Yay!",
                  description: 'Request Sent',
                  status: "success", // You can use any status like "success", "error", "warning", or "info"
                  duration: 5000,
                  isClosable: true,
                  variant: "solid", // Use "solid" for a solid background
                  // Add your custom background color
                  // Example: backgroundColor: "your-color-hex-code"
                  // backgroundColor: "your-color-hex-code",
                  
                  colorScheme:'yellow',
                });
              }).catch(e => {
                toast({
                  title: "Oops!",
                  description: e.response.data.message,
                  status: "error", // You can use any status like "success", "error", "warning", or "info"
                  duration: 5000,
                  isClosable: true,
                  variant: "solid", // Use "solid" for a solid background
                  // Add your custom background color
                  // Example: backgroundColor: "your-color-hex-code"
                  // backgroundColor: "your-color-hex-code",
                  
                  colorScheme:'red',
                });
              })
            console.log(response)
        }catch(e){
            console.log(e)
        }
    }

    const handleRequestConnection = async () =>{
        try{
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/connections/request/connection`, {user1 : user, user2 : profile})
                              .then(res => {
                                toast({
                                  title: "Yay!",
                                  description: 'Request Sent',
                                  status: "success", // You can use any status like "success", "error", "warning", or "info"
                                  duration: 5000,
                                  isClosable: true,
                                  variant: "solid", // Use "solid" for a solid background
                                  // Add your custom background color
                                  // Example: backgroundColor: "your-color-hex-code"
                                  // backgroundColor: "your-color-hex-code",
                                  
                                  colorScheme:'yellow',
                                });
                              }).catch(e => {
                                toast({
                                  title: "Oops!",
                                  description: e.response.data.message,
                                  status: "error", // You can use any status like "success", "error", "warning", or "info"
                                  duration: 5000,
                                  isClosable: true,
                                  variant: "solid", // Use "solid" for a solid background
                                  // Add your custom background color
                                  // Example: backgroundColor: "your-color-hex-code"
                                  // backgroundColor: "your-color-hex-code",
                                  
                                  colorScheme:'red',
                                });
                              })
            if(response.status === 200){
            }
            console.log(response)
        }catch(e){
            console.log(e)
        }
    }

    const handleRequestFollow = async () => {}

    return <>
    <Center width="100%" >
        {type === 'C' ? <Button width="80%" bgColor={colors.primary} _hover={{bgColor:colors.primary_dim}  } onClick={handleRequestMember}>Request Member</Button> : null}
        {type === 'P' ? <HStack width="100%" justifyContent="space-evenly"> <Button width="40%" bgColor={colors.primary} _hover={{bgColor:colors.primary_dim} } onClick={handleRequestConnection}>Connect</Button> 
        {/* <Button width="40%" bgColor={colors.primary} _hover={{bgColor:colors.primary_dim} } onClick={handleRequestFollow}>Follow</Button>  */}
        </HStack>: null}
    </Center>
    </>
}