import React, { useState } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Divider,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  FormControl,
  ModalFooter,
  Input,
  useDisclosure,
  ModalContent,
  FormLabel,
  HStack,
  useToast,
} from "@chakra-ui/react";

import { FallingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

// File Imports
import GeneralButton from "../Components/GeneralButton";
import { colors } from "../globalStyles/colors";

export default function RegisterUser() {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const email = location.state?.email || "E"

  console.log(email + " in RegisterUser")

  const [inputType, setInputType] = useState("email");
  const [buttonMessage, setButtonMsg] = useState("Use Phone");
  const [title, setTitle] = useState("First Name");
  const [isSLoading, setSLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [dob, setDob] = useState("");
  const [values, setValues] = useState([]);
  const [inputValue, setInputValues] = useState('')



  const handleSignIn = async (title) => {
    // setSLoading(false);
    // navigate('/homepage')
    if(inputValue === '' && title !== "dob") {
      setInputValues('');
        setSLoading(false);
      toast({
        title: "Please enter " + title,
        description: "Invalid Input",
        status: "error", // You can use any status like "success", "error", "warning", or "info"
        duration: 5000,
        isClosable: true,
        variant: "solid", // Use "solid" for a solid background
        // Add your custom background color
        // Example: backgroundColor: "your-color-hex-code"
        // backgroundColor: "your-color-hex-code",

        colorScheme: "yellow",
      });
      return;
    }
    setValues([...values, inputValue]);
    if(title === "First Name")
        setTitle("Last Name")
    else if(title === "Last Name"){
        setTitle("dob")
        setInputType("date")
    }
    else if(title === "dob"){
      setInputType("text")
        setTitle("username")
        
    }

      console.log(values)

    // Clear the inputValue for the next input
        setInputValues('');
        setSLoading(false);

    if(title === "username"){
    setTimeout(() => {
      setSLoading(false);
    }, 1000);
    var url = "";

    url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/register/user`;
    const data = {
      email:email,
      firstName: values[0],
      lastName: values[1],
      userName :inputValue, 
      dob :  values[2]
    };
    try {
      const response = await axios
        .post(url, data, { withCredentials: true })
        .then((res) => {
            toast({
                title: "You are all set!",
                description: res.data.message,
                status: "success", // You can use any status like "success", "error", "warning", or "info"
                duration: 5000,
                isClosable: true,
                variant: "solid", // Use "solid" for a solid background
                // Add your custom background color
                // ackgroundColor: "your-color-hex-code",
                
                colorScheme:'yellow',
              });
          console.log(res);
          return res;
        })
        .catch((err) => {
          var error = err.response.data.message;
          console.log(error);
          toast({
            title: "Invalid User",
            description: error,
            status: "error", // You can use any status like "success", "error", "warning", or "info"
            duration: 5000,
            isClosable: true,
            variant: "solid", // Use "solid" for a solid background
            // Add your custom background color
            // Example: backgroundColor: "your-color-hex-code"
            // backgroundColor: "your-color-hex-code",

            colorScheme: "yellow",
          });
          return err;
        });

      if (response.status == 200) {
        navigate("/login");
      }
    } catch (e) {}
}

  };

  const handleChange = (e) => {
    setInputValues(e.target.value)
  };


  return (
    <Flex
      direction="row"
      align="center"
      justify="center"
      bgColor="black"
      h="100vh"
      w="100vw"
    >
      <Flex flex="3" flexDirection="row-reverse">
        <Stack textAlign="center" spacing={3}>
          <Heading fontSize="250px" color="rgb(229, 224, 177)">
            Cluster
          </Heading>
          <Heading fontSize="30px" color="rgb(229, 224, 177, 0.8)">
            Explore connections, seamless information <br></br> with Cluster!
          </Heading>
        </Stack>
      </Flex>

      <Flex flex="1">
        <Center height="200px" m="5">
          <Divider orientation="vertical" />
        </Center>
        <Stack textAlign="center" spacing={3} mt="12px" justifyContent="center">
          <Heading size="sm" color="rgb(229, 224, 177, 0.9)">
            Let's know you better!
          </Heading>
          <FormControl>
            {inputType === "date" ? <FormLabel fontSize="12px" color={colors.primary}>Date Of Birth</FormLabel> : null}
            <FormControl mb="10px">
              <Input
                errorBorderColor="red.300"
                focusBorderColor="rgb(229, 224, 177)"
                borderColor="rgb(229, 224, 177, 0.7)"
                placeholder={title}
                color="white"
                _placeholder={{ opacity: 0.5, color: "rgb(229, 224, 177)" }}
                type={inputType}
                value={inputValue}
                onChange={(e) => {
                  handleChange(e, title)
                }}
                
              />
            </FormControl>

            <GeneralButton
              text="Next"
              size="sl"
              isLoading={isSLoading}
              spinner={
                <FallingLines
                  color="rgb(229,224,177)"
                  width="50"
                  height="50"
                  visible={true}
                  opacity={1}
                  ariaLabel="falling-circles-loading"
                />
              }
              onClick={() => {
                setSLoading(true);
                handleSignIn(title);
              }}
            ></GeneralButton>
          </FormControl>
        </Stack>
      </Flex>
    </Flex>
  );
}
