import { Flex, Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import openSocket from "socket.io-client";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useToast, VStack, Text } from "@chakra-ui/react";
import { IoMdSettings } from "react-icons/io";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { MdPrivacyTip } from "react-icons/md";
import { FaCamera } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
// user Imports
import NavBar from "../Landing/NavBar";
import SelfPosts from "./SelfPosts";
import ProfileBar from "./ProfileBar";
import CreatePostComp from "../../Components/CreatePostComp";
import Feed from "../Landing/Feed";
import { colors } from "../../globalStyles/colors";
import MenuButton from "../../Components/MenuButton";

export default function ProfileHome() {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [cluster, setCluster] = useState(null)
  const [isShowPosts, setShowPosts] = useState(false)
  // const [userDetails, setUserDetails] = useState(null);

  
  
  const [cookies, setCookie, removeCookie] = useCookies(['payload', 'essential']);
  const location = useLocation()

  const userDetails = location.state?.userDetails || "E"

  const clusterName = location.state?.clusterName

  const type = location.state?.type

  console.log(userDetails + " in Profile Home")
  console.log(clusterName + " in Profile Home")

  const fetchUser = () =>{
    
  }

  useEffect(() => {
    const fetchClusterDetails = async () => {
      console.log("here " + clusterName)
        if(clusterName){
          console.log("in if " + clusterName)
          await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/cluster/getCluster/${clusterName}`)
          .then(res => 
            {console.log(res)
              setCluster(res.data)
            })
          .catch(error => console.error("Error fetching cluster:", error));
          }
    }
     fetchClusterDetails();
  }, [clusterName])
//   const updateChatScreenData = (data) => {
//     setChatScreenData(data);
//     console.log(data);
//   };

//   const updateShowCreatePost = showPost => {
//     setShowCreatePost(showPost)
//   }

//   useEffect(() => {
//     const fetchUserData = async() => {
//       try{
//         const response = await axios.get('process.env.REACT_APP_SERVER_BASE_URL/user/details', { params : {email : email}} ).then(
//           res => {
//             toast({
//               title: "user details fetched",
//               description: res.data.message,
//               status: "success", // You can use any status like "success", "error", "warning", or "info"
//               duration: 5000,
//               isClosable: true,
//               variant: "solid", // Use "solid" for a solid background
//               // Add your custom background color
//               // Example: backgroundColor: "your-color-hex-code"
//               // backgroundColor: "your-color-hex-code",
              
//               colorScheme:'yellow',
//             });
//             return res;
//           }
//         ).catch(err => {
//           toast({
//             title: "Unable to fetch user",
//             description: err.data.message,
//             status: "error", // You can use any status like "success", "error", "warning", or "info"
//             duration: 5000,
//             isClosable: true,
//             variant: "solid", // Use "solid" for a solid background
//             // Add your custom background color
//             // Example: backgroundColor: "your-color-hex-code"
//             // backgroundColor: "your-color-hex-code",
            
//             colorScheme:'red',
//           });
//         })
  
//         console.log(response.data)
//         if(response.status === 200)
//           setUserDetails(response.data.data)
//       }catch(e){

//       }

//     }
//     fetchUserData()
//   }, [])

//   useEffect(() => {

//     const io = openSocket("process.env.REACT_APP_SERVER_BASE_URL");
//     console.log("Socket Object:", io);

//     io.on("connect", () => {
//       console.log("Connected to the server");
//       setSocket(io);
//       setIsLoading(false); // Set loading to false once connected
//     });

//     io.on("disconnect", () => {
//       console.log("Disconnected from the server");
//     });

//     return () => {
//       // Clean up the socket connection if needed
//       io.disconnect();
//     };
//   }, []);

  // socket.on('message', message => {
  //   receiveMessage(message)
  // })

  if(type === 'C'  && cluster)
    return (
      <Box minH='100vh' height="100%" width="100%" bgColor="black" boxSizing="border-box" >
      <Flex
      // justifyContent="space-around"
      flexDirection="row"
      flexWrap="nowrap"
      height="100%"
      width="100%"
      bgColor="black"
      borderColor="rgb(229,224,177,0.7)"
      border="solid"
      borderWidth="0 2px 0 0"
      // p="10px"
      >
        <Box width={isShowPosts ? '60%' : '80%'} ml={5}>
          <ProfileBar userDetails={userDetails} cluster={cluster} type='C' profile={cluster} />
        </Box>
    <Box ml="10px"></Box>
    
    {/* <SelfPosts type={type} socket={socket} userDetails={userDetails} /> */}
    {isShowPosts && <Feed userDetails={userDetails} scope='C' clusterName={clusterName} />}
    
    <VStack height='100%' alignItems='left' width='25%'>
              <Box
                backgroundColor="rgba(26, 24, 24, 0.8)"
                boxShadow="1px 2px 2px grey"
                borderRadius="10px"
                width="90%"
                minHeight="calc(100% + 10px)"
                alignSelf="center"
                height='100%' 
                mt={2}
              >
                <VStack alignItems="left" spacing={-1} ml={2} height="100%">
                  <Text fontSize="14px" color={colors.primary_dim}>
                    Bio
                  </Text>
                  <Text fontSize='14px' color="white">Talks about web development, DSA and  problem Solving Talks about web development, DSA and problem Solving </Text>
                  <Box mt={2}/>
                </VStack>
              </Box>
            
              <MenuButton button_icon_margin={2} mr={5} Icon={<IoMdSettings/>} text='Settings'/>
              <MenuButton button_icon_margin={2} mr={5} Icon={<MdDriveFileRenameOutline/>} text='About'/>
              <MenuButton button_icon_margin={2} mr={5} Icon={<MdPrivacyTip/>} text='Security & Privacy'/>
              <MenuButton button_icon_margin={2} mr={5} Icon={<FaCamera/>} text='Posts' onClick={() => setShowPosts(!isShowPosts)} />
              <MenuButton button_icon_margin={2} mr={5} Icon={<GrUserManager/>} text='Curators'/>
            </VStack>
    </Flex>
  </Box>
  )

  if(type === 'P')return (
    <>
      {/* {isLoading ? (
        <p>Loading...</p>
      ) :  */}
      {/* ( */}
        <Box minH='100vh' height="100%" width="100%" bgColor="black" boxSizing="border-box" >
          <Flex
          // justifyContent="space-around"
          flexDirection="row"
          flexWrap="nowrap"
          height="100%"
          width="100%"
          bgColor="black"
          borderColor="rgb(229,224,177,0.7)"
          border="solid"
          borderWidth="0 2px 0 0"
          // p="10px"
          >
            <Box width={isShowPosts ? '60%' : '80%'} ml={5}>
              <ProfileBar userDetails={userDetails} type='P' profile={userDetails} />
            </Box>
            {/* <Box ml="10px"></Box> */}
            
            {/* <SelfPosts type={type} socket={socket} userDetails={userDetails} /> */}
            {isShowPosts && <Feed userDetails={userDetails} scope='P' />}
            
            <VStack height='100%' alignItems='left' width='25%'>
              <Box
                backgroundColor="rgba(26, 24, 24, 0.8)"
                boxShadow="1px 2px 2px grey"
                borderRadius="10px"
                width="90%"
                minHeight="calc(100% + 10px)"
                alignSelf="center"
                height='100%' 
                mt={2}
              >
                <VStack alignItems="left" spacing={-1} ml={2} height="100%">
                  <Text fontSize="14px" color={colors.primary_dim}>
                    Bio
                  </Text>
                  <Text fontSize='14px' color="white">Talks about web development, DSA and  problem Solving Talks about web development, DSA and problem Solving </Text>
                  <Box mt={2}/>
                </VStack>
              </Box>
            
              <MenuButton button_icon_margin={2} mr={5} Icon={<IoMdSettings/>} text='Settings'/>
              <MenuButton button_icon_margin={2} mr={5} Icon={<MdDriveFileRenameOutline/>} text='About'/>
              <MenuButton button_icon_margin={2} mr={5} Icon={<MdPrivacyTip/>} text='Security & Privacy'/>
              <MenuButton button_icon_margin={2} mr={5} Icon={<FaCamera/>} text='Posts' onClick={() => setShowPosts(!isShowPosts)} />
            </VStack>
          </Flex>
        </Box>
      {/* ) */}
      {/* } */}
    </>
  );
}
