import React from "react";

import CryptoJS from "crypto-js";

export function encryption(originalText, key){
    return CryptoJS.AES.encrypt(originalText, key).toString();
}

export function decryption(encryptedText, key){
    return CryptoJS.AES.decrypt(encryptedText, key).toString(CryptoJS.enc.Utf8);
}