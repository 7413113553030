import {
  FormControl,
  Input,
  FormLabel,
  Divider,
  Stack,
  HStack,
  Button,
  Heading,
  Text,
  Center, useToast
} from "@chakra-ui/react";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import PacmanLoader from "react-spinners/PacmanLoader";
import GeneralButton from "../Components/GeneralButton";
import { AiOutlineGoogle } from "react-icons/ai";
import BarLoader from "react-spinners/BarLoader";
import { FaGoogle } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";



import RunningLineButton from "../animations/RunningLineButton";


import {FallingLines} from 'react-loader-spinner'; 

export default function SignUp() {
    const navigate = useNavigate();
    const googleLoginRef = useRef();

    const [inputType, setInputType] = useState('');
    const [buttonMessage, setButtonMsg] = useState('Use Phone');
    const [title, setTitle] = useState('Email');
    const [isGLoading, setGLoading] = useState(false);
    const [isFLoading, setFLoading] = useState(false);
    const [isELoading, setELoading] = useState(false);
    const [isSLoading, setSLoading] = useState(false);
    const [isOtpLoading, setOTPloading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [value, setValue] = useState("");
    const [otp, setOTP] = useState("");
    const toast = useToast();

    const onPhoneClick = () =>{
        if(inputType === ''){
            setInputType('tel')
            setButtonMsg('use email')
            setTitle('Mobile Number')
        }
        else{
            setInputType('')
            setButtonMsg('Use Phone')
            setTitle('Email') 
        }
    }

    const handleLogin = async(type) => {
      // setLoading(true);
      var url = "";
      if(value === "" && type ==='E') {
        setELoading(false)
        toast({
          title: "Oops!",
          description: "Please enter email to continue",
          status: "error", // You can use any status like "success", "error", "warning", or "info"
          duration: 5000,
          isClosable: true,
          variant: "solid", // Use "solid" for a solid background
          // Add your custom background color
          // Example: backgroundColor: "your-color-hex-code"
          // backgroundColor: "your-color-hex-code",
          
          colorScheme:'yellow',
        });
        return;
      }

      // console.log(value)
      // if(!value) {
      //   setTimeout(() => setOTPloading(false), 0)
      //   console.log("ran")
      //   return;
      // }
      if(type === 'E')
        url=`${process.env.REACT_APP_SERVER_BASE_URL}/auth/method1`;
      else if(type==='G'){
        url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/google`;
      // window.location.href = url;  // Redirect to Google OAuth endpoint
      window.open(
        url,
        "_self"
      )
      return;
        // setGLoading(false);
        // toast({
        //   title: "Oops!",
        //   description: "Google Signin will be available soon",
        //   status: "error", // You can use any status like "success", "error", "warning", or "info"
        //   duration: 5000,
        //   isClosable: true,
        //   variant: "solid", // Use "solid" for a solid background
        //   // Add your custom background color
        //   // Example: backgroundColor: "your-color-hex-code"
        //   // backgroundColor: "your-color-hex-code",
          
        //   colorScheme:'yellow',
        // });
        // return;
        // googleLoginRef.current && googleLoginRef.current.click();
      }
      else if(type==='H'){
        url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/github`;
      // window.location.href = url;  // Redirect to Google OAuth endpoint
      window.open(
        url,
        "_self"
      )
      return;
      }
      else if(type === 'S'){
        setSLoading(false);
        navigate('/login')
        return;
      }
      const data = {'value' : value}
      
      try{
        const response = await axios.post(url, data,) 
        console.log(response)
        if(response.status === 201){
          setOTPloading(false);
          setShowOTP(true);
        }
    }
    catch(e){
        setELoading(false)
        toast({
          title: "Oops!",
          description: "User Already Exists, please SignIn to continue",
          status: "error", // You can use any status like "success", "error", "warning", or "info"
          duration: 2000,
          isClosable: true,
          variant: "solid", // Use "solid" for a solid background
          // Add your custom background color
          // Example: backgroundColor: "your-color-hex-code"
          // backgroundColor: "your-color-hex-code",
          
          colorScheme:'yellow',
          position:"top-right"
        });
        return;
    }
  }

    const verifyOTP = async(type) => {
      const data = {'value' : value, 'otp' : otp};

      var url=`${process.env.REACT_APP_SERVER_BASE_URL}/auth/method1/verify`;

      try{
        const response = await axios.post(url, data,) 
        console.log(response)
        if(response.status === 201){
          setOTPloading(false);
          setShowOTP(false);
          navigate('/signup/password', {state : {email : value}})
        }
      }
      catch(e){
        setELoading(false)
        toast({
          title: "Oops!",
          description: "Please try requesting OTP again",
          status: "error", // You can use any status like "success", "error", "warning", or "info"
          duration: 5000,
          isClosable: true,
          variant: "solid", // Use "solid" for a solid background
          // Add your custom background color
          // Example: backgroundColor: "your-color-hex-code"
          // backgroundColor: "your-color-hex-code",
          
          colorScheme:'yellow',
        });
        return;
      }
    }
    const responseGoogle = (response) => {
      console.log(response);
      
      setGLoading(false);
      if (response.tokenId) {
         
          navigate('/home');
      } else {
         
          console.error("Failed Google Sign In");
      }
    };

  return (
    <FormControl>
        <Center>
        <Heading size='lg' color="white" mb={5} >Sign Up</Heading>
        </Center>
      <FormControl>
        <Center>
        <Input
            height='50px'
            placeholder={title}
            value={value}
            type={inputType}
            onChange={(e) => setValue(e.target.value)}
            borderColor="orange"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
            _placeholder={{ color: "orange"}}
            bgColor="white"
            textColor="orange"
            _hover={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            _focus={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            width='90%'
          />
        </Center>
                </FormControl>

      {showOTP && <FormControl mt='25px'>
      <Center>
        <Input
            height='50px'
            placeholder={`Enter OTP sent to your ${title}`}            
            value={otp}
            type='number'
            onChange={(e) => setOTP(e.target.value)}
            borderColor="orange"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
            _placeholder={{ color: 'rgb(255, 250, 240)'}}
            bgColor="rgb(250, 171, 30)"
            _hover={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            _focus={{ borderColor:"orange",  boxShadow:"0 8px 8px rgba(0, 0, 0, 0.2)"}}
            width='90%'
          />
        </Center>
      </FormControl>}

      {/* <GeneralButton text="Continue With Phone" size="c" fontSize='10' width='100' height='10'></GeneralButton>
       */}
       <br></br>

      {/* {!showOTP &&  <HStack alignContent='end'>
        <Button variant='link' size='xs' colorScheme='yellow' onClick={onPhoneClick} mt={2} mb={4}>{buttonMessage}</Button>
       </HStack>} */}

      {!showOTP && <Center>
        <GeneralButton bdColor="orange" text={`Continue With ${title}`} size="lg" isLoading={isELoading} spinner={<FallingLines
            color="rgb(229,224,177)"
           height="50%"
            visible={true}
            ariaLabel="falling-circles-loading"
          />} onClick={
        () => {
          setELoading(true)
        handleLogin('E')}}></GeneralButton>
        </Center>}

        {
          showOTP && <Center> <GeneralButton text="Enter OTP" size="lg" isLoading={isOtpLoading} spinner={<FallingLines
          size={15}
            color="rgb(229,224,177)"
            visible={true}
            ariaLabel="falling-circles-loading"
          />} onClick={
        () => {
          setOTPloading(true)
        verifyOTP('E')}}></GeneralButton>
        </Center>
        }

      <HStack m={2}><Divider orientation="horizontal" mt={4} mb={4}></Divider> <Text fontWeight="bold">or</Text> <Divider orientation="horizontal" mt={4} mb={4}></Divider> 
      </HStack>
      {/* <HStack justifyContent='space-evenly'> */}
      <Center mt={5} mb={3}>
      <GeneralButton leftIcon={<FaGoogle/>} bgColor='whitesmoke' bdColor='white' txtColor='orange' text="Google" mt={4} mb={4} size="sl" isLoading={isGLoading} spinner={<PacmanLoader size={15} bgColor="white" color="white"/>} onClick={() => {
        setGLoading(true);
      
        handleLogin('G')}}></GeneralButton></Center>

      <Center mt={5} mb={3}>
        <GeneralButton leftIcon={<FaGithub/>} bgColor='whitesmoke' bdColor='white' txtColor='orange' text="Github" mt={4} mb={4} size="sl" isLoading={isFLoading} onClick={
          () => {
            setFLoading(true);
          handleLogin('H')}}
          
          spinner={<PacmanLoader size={15} color="rgb(229,224,177)"/>}
          ></GeneralButton>
          </Center>
          
      {/* </HStack> */}
      <Center mt={5} mb={3}>
        <GeneralButton text="Sign In With Email" size="sl" isLoading={isSLoading} spinner={
        <BarLoader width="300px" height="4px" color="rgb(229,224,177)"/>}
         onClick={
        () => {
          setSLoading(true);
        handleLogin('S')}}></GeneralButton>
        </Center>
      
    </FormControl>
  );
}
