import React, { useState, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Box,
  Textarea,
  Flex,
  Avatar,
  IconButton,
  Heading,
  Text,
  Image,
  useToast,
  AlertDialog
} from "@chakra-ui/react";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import { GrCluster } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";

// user Imports
import { colors } from "../globalStyles/colors";
import ImagePicker from "./ImagePicker";
import axios from "axios";
import AlertDialogBox from "./AlertDialogBox"

export default function CreatePostComp({ userDetails, updateShowCreatePost, scope, clusterName, enclaveName }) {
  const [isLiked, setLiked] = useState(false);
  const [postText, setPostText] = useState('');
  const [resize, setResize] = useState('none');
  const [preSignedUrl, setPresignedUrl] = useState(null);
  const toast = useToast();
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sendWithoutImage, setSendWithoutImage] = useState(false);
  const [cardHeight, setCardHeight] = useState('300px'); // state to manage card height

  const handleImageSelected = async (imageData, file) => {
    const awsurl = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/media/upload/global/get/presignedurl`, { params: { metaData: file, userName: userDetails?.userName } }).then(
      res => {
        console.log(res);
        setSelectedImage(imageData);
        setCardHeight('2xl'); // adjust card height when image is selected
        return res.data;
      }
    ).catch(
      err => {
        console.log(err);
        toast({
          title: "Couldn't upload image",
          description: "Server error",
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'red',
        });
      }
    );
    console.log("awsurl " + awsurl);
    if (awsurl) {
      setPresignedUrl(awsurl.preSignedUrl);
      setFileName(awsurl.fileName);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        handleImageSelected(reader.result, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirm = () => {
    setSendWithoutImage(true);
    setIsOpen(false);
    handleShare();
  };

  const handleShare = async () => {
    if (!file && sendWithoutImage === false) {
      setIsOpen(true);
      console.log("in here");
      return;
    }

    if (file) {
      try {
        const response = await axios.put(preSignedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        console.log('Image uploaded successfully!', response);

      } catch (error) {
        console.error('Error uploading image:', error.message);
        toast({
          title: "Couldn't upload Image",
          description: "Try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
          variant: "solid",
          colorScheme: 'red',
        });
        return;
      }
    }

    if (scope === 'G')
      await uploadGlobally()
    else if (scope === 'C')
      await uploadInCluster()
    else if (scope === 'E')
      await uploadInEnclave()
  }

  const uploadInCluster = async () => {
    if (postText === '') {
      toast({
        title: "Ooops!",
        description: "Add something to post",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        colorScheme: 'yellow',
      });
      return;
    }
    const data = {
      story: postText,
      creator: userDetails,
      createdAt: Date.now(),
      fileName: fileName,
      metaData: file,
      clusterName: clusterName
    }

    console.log(data)

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/media/upload/cluster/post`, data).then(
        res => {
          console.log(res)
          toast({
            title: "Yay!",
            description: "Post updated",
            status: "success",
            duration: 5000,
            isClosable: true,
            variant: "solid",
            colorScheme: 'yellow',
          });
          return res;
        }).catch(err => {
          console.log(err)
        })
      if (response.status === 201)
        updateShowCreatePost(false)
    }
    catch (e) {

    }
  }

  const uploadGlobally = async () => {
    if (postText === '') {
      toast({
        title: "Ooops!",
        description: "Add something to post",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        colorScheme: 'yellow',
      });
      return;
    }
    const data = {
      story: postText,
      creator: userDetails,
      createdAt: Date.now(),
      fileName: fileName,
      metaData: file
    }

    console.log(data)

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/media/upload/global/post`, data).then(
        res => {
          console.log(res)
          toast({
            title: "Yay!",
            description: "Post updated",
            status: "success",
            duration: 5000,
            isClosable: true,
            variant: "solid",
            colorScheme: 'yellow',
          });
          return res;
        }).catch(err => {
          console.log(err)
        })
      if (response.status === 201)
        updateShowCreatePost(false)
    }
    catch (e) {

    }
  }

  const uploadInEnclave = async () => {
    if (postText === '') {
      toast({
        title: "Ooops!",
        description: "Add something to post",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        colorScheme: 'yellow',
      });
      return;
    }
    const data = {
      story: postText,
      creator: userDetails,
      createdAt: Date.now(),
      fileName: fileName,
      metaData: file,
      clusterName: clusterName,
      enclaveName: enclaveName
    }

    console.log(data)

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/media/upload/enclave/post`, data).then(
        res => {
          console.log(res)
          toast({
            title: "Yay!",
            description: "Post updated",
            status: "success",
            duration: 5000,
            isClosable: true,
            variant: "solid",
            colorScheme: 'yellow',
          });
          return res;
        }).catch(err => {
          console.log(err)
        })
      if (response.status === 201)
        updateShowCreatePost(false)
    }
    catch (e) {

    }
  }

  return (
    <>
      <Card
        maxW="2xl"
        minW="xl"
        border="solid"
        borderColor={colors.primary_dim}
        borderWidth="3px"
        bgColor="black"
        color="white"
        overflow="auto"
        height={cardHeight} // adjust height dynamically
        maxH="2xl"
      >
        <CardHeader>
          <Flex spacing="4">
            <Flex
              flex="1"
              gap="4"
              alignItems="center"
              flexWrap="wrap"
            >
              <Avatar
                name="Segun Adebayo"
                src="https://bit.ly/sage-adebayo"
              />

              <Box>
                <Heading size="sm">{userDetails ? userDetails.firstName + " " + userDetails.lastName : ""}</Heading>
                <Text fontSize="12px">{userDetails ? "@" + userDetails.userName : ""}</Text>
              </Box>
            </Flex>
            <ImagePicker onImageChange={handleImageChange} />
            <IconButton
              variant="ghost"
              fontSize="1.5rem"
              color={colors.primary}
              aria-label="See menu"
              icon={<GrCluster />}
              _hover={{ bgColor: "black" }}
              mr="5px"
            />
            <IconButton
              variant="ghost"
              fontSize="1.5rem"
              color={colors.primary}
              aria-label="See menu"
              icon={<BsFillSendArrowUpFill />}
              _hover={{ bgColor: "black" }}
              onClick={handleShare}
            />
            <IconButton
              variant="ghost"
              fontSize="1.5rem"
              color={colors.primary}
              aria-label="See menu"
              icon={<RxCross2 />}
              _hover={{ bgColor: "black" }}
              onClick={() => updateShowCreatePost(false)}
            />
          </Flex>
        </CardHeader>
        <CardBody>
          <Textarea
            placeholder='Here is a sample placeholder'
            size='sm'
            resize={resize}
            variant="unstyled"
            minH="100px"
            m="0px"
            mb="0" // Reduce margin-bottom to zero
            value={postText}
            onChange={(e) => setPostText(e.target.value)}
          />
        </CardBody>
        {selectedImage && <Image
          objectFit="cover"
          src={selectedImage}
          alt="Selected Image"
          mt="0" // Reduce margin-top to zero
          maxH="300px" // Ensure image doesn't exceed the card height
        />}
        <AlertDialogBox
          title="Image not selected!"
          onCancel={() => {
            setSendWithoutImage(false)
            setIsOpen(false)
          }}
          onConfirm={() =>
            handleConfirm()
          }
          message="Do you want to post without image?"
          isOpen={isOpen} />
      </Card>
    </>
  );
}
