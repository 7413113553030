import React, { useState } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  FormControl,
  Input,
  Button,
  useToast, 
  Stack
} from "@chakra-ui/react";
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import GeneralButton from "../Components/GeneralButton";
import { AiOutlineGoogle } from "react-icons/ai";
import BarLoader from "react-spinners/BarLoader";

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/auth/reset-password/${token}`, { newPassword: password });
      toast({
        title: "Success",
        description: "Password has been reset",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bgColor="black" h="100vh" w="100vw">
      <Center h="100vh">
      <Stack textAlign="center" spacing={3}>
              <Heading size="4xl" color="rgb(229, 224, 177, 0.9)">
                Cluster
              </Heading>
              <Heading size="sm" color="wheat">
              Coordinate, Coalesce, Cultivate, <br></br>on Cluster!
              </Heading>
            </Stack>
        <Box bgColor="black" p="10">
          <Flex direction="column" align="center" justify="center">
            <Heading size="sm" color="rgb(229, 224, 177, 0.9)">
              Final step to reset 
              your password!
            </Heading>
            <FormControl>
              <Input
                placeholder="New password"
                mt={4}
                type="password"
                value={password}
                borderColor="rgb(229, 224, 177, 0.7)" color="white" focusBorderColor='rgb(229, 224, 177)'
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <Input
                placeholder="Confirm password"
                mt={4}
                type="password"
                value={confirmPassword}
                borderColor="rgb(229, 224, 177, 0.7)" color="white" focusBorderColor='rgb(229, 224, 177)'
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <Box mt={2}></Box>
            <GeneralButton text="Reset Password" size="sl" spinner={<BarLoader width="300px" height="4px" color="rgb(229,224,177)"/>} onClick={
          handleResetPassword}></GeneralButton>
          </Flex>
        </Box>
      </Center>
    </Box>
  );
}
