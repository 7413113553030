import React, { useEffect, useState } from "react";
import { Center, Box, HStack, Text, VStack, Heading, useToast } from "@chakra-ui/react";
import axios from "axios";
import { FaUserFriends } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { IoOpenOutline } from "react-icons/io5";

// user imports
import { colors } from "../../../globalStyles/colors";
import ListItemBox from "../../../Components/ListItemBox";
import { useNavigate } from "react-router-dom";
import LogoSpinner from "../../../animations/LogoSpinner";

// Custom hook for fetching data
const useFetchData = (title, userDetails) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var url;

  console.log(userDetails)

  useEffect(() => {

    const fetchData = async () => {
      
      if(title === "Requests"){
       url = `${process.env.REACT_APP_SERVER_BASE_URL}/connections/get/connection/requests`
      }
      else if(title === "My Friends"){
       url = `${process.env.REACT_APP_SERVER_BASE_URL}/connections/get/allFriends`
      }
      else if(title === "My Clusters"){
        url = `${process.env.REACT_APP_SERVER_BASE_URL}/connections/get/alluserClusters`
      }
      try {
        const response = await axios.get(url, {params : {user : userDetails?.userName}});
        console.log(response)
        setData(response.data);
        if(response.status === 200)
          setError(null)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  },[userDetails]);

  return { data, loading, error };
};

export default function Entity({ updateChatScreenData,title, userDetails,updateShowUserProfile }) {
  const toast = useToast()
  const navigate = useNavigate();
  const { data: userList, loading, error } = useFetchData(
    title, userDetails
  );
  const handleAcceptConnection = async user =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/connections/accept/connection`, {user1 : user, user2 : userDetails})
                          .then(res => {
                            toast({
                              title: "Yay!",
                              description: res.data.message,
                              status: "success", // You can use any status like "success", "error", "warning", or "info"
                              duration: 5000,
                              isClosable: true,
                              variant: "solid", // Use "solid" for a solid background
                              // Add your custom background color
                              // Example: backgroundColor: "your-color-hex-code"
                              // backgroundColor: "your-color-hex-code",
                              
                              colorScheme:'yellow',
                            });
                          }).catch(e => {
                            toast({
                              title: "Oops!",
                              description: e.response.data.message,
                              status: "error", // You can use any status like "success", "error", "warning", or "info"
                              duration: 5000,
                              isClosable: true,
                              variant: "solid", // Use "solid" for a solid background
                              // Add your custom background color
                              // Example: backgroundColor: "your-color-hex-code"
                              // backgroundColor: "your-color-hex-code",
                              
                              colorScheme:'red',
                            });
                          })
        if(response.status === 200){
        }
        console.log(response)
    }catch(e){
        console.log(e)
    }
}

const handleShowProfile = user =>{
  updateShowUserProfile(user)
}

  if (loading) {
    return <Center>
    <Box
      borderColor={colors.primary_dim}
      // borderRadius="1px"
      // borderWidth="1px 0px 1px 0px"
      height="180px"
      width="100%"
      mr={1}
    >
      <Box boxShadow={`0px 2px 4px ${colors.bgDark}`} borderRadius="sm" bgColor='grey'>
        <HStack>
          <Text fontSize="12" fontWeight='Bold' color="white" p="5px">
            {title ? title : 'Cannot Fetch Data'}
          </Text>
          <FaUserFriends color="white" p="5px" />
        </HStack>
      </Box>
      {/* <Heading>Error</Heading> */}
      <Box
        width="100%"
        height="100%" // Ensure the Box takes full height of its parent
        bgColor={colors.bgDark}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LogoSpinner width="25px" />
      </Box>
    </Box>
  </Center>
  
    // </Box>
  }

  if (error) {
    return <Center>
    <Box
      borderColor={colors.primary_dim}
      // borderRadius="1px"
      // borderWidth="1px 0px 1px 0px"
      height="140px"
      width="100%"
      mr={1}
    >
      <Box boxShadow={`0px 2px 4px ${colors.bgDark}`} borderRadius="sm" bgColor='grey'>
        <HStack>
          <Text fontSize="12" fontWeight='Bold' color="white" p="5px">
            {title ? title : 'Cannot Fetch Data'}
          </Text>
          <FaUserFriends color="white" p="5px" />
        </HStack>
      </Box>
      {/* <Heading>Error</Heading> */}
      <Box
        width="100%"
        height="100%" // Ensure the Box takes full height of its parent
        bgColor={colors.bgDark}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LogoSpinner width="25px" />
      </Box>
    </Box>
  </Center>
  }

  // Assuming userList is a state variable or some array in your code
  if (Array.isArray(userList) && userList.length > 0) {
    console.log(userList[1]);
  } else {
    console.error("userList is either not an array or is empty");
  }

  const handleChatBox = user =>{
    if(typeof updateChatScreenData === 'function')
    updateChatScreenData({...user})
    else
      console.log("something else")
  }

  const handleOpenCluster = user =>{
    navigate(`/cluster?cluster=${user.clusterName}`, {state : {user : userDetails}})
  }

  return (
    <Center>
      <Box
        borderColor={colors.primary_dim}
        // borderRadius="1px"
        // borderWidth="1px 0px 1px 0px"
        height="200px"
        width="100%"
        mr={1}
      >
  <Box boxShadow={`0px 2px 4px ${colors.bgDark}`} borderRadius="lg" bgColor={title === 'My Friends' ? colors.primary_dim : 'grey'}>
          <HStack>
            <Text fontSize="14px" fontWeight='Bold' color='white' p="5px">
              {title ?  title : 'Cannot Fetch Data'}
            </Text>
            <FaUserFriends color='white' p="5px" />
          </HStack>
        </Box>

        <VStack
          align="stretch"
          overflowY="auto"
          height="80%"
          sx={{
            "&::-webkit-scrollbar": {
              w: "2",
              // bg: `yellow.100`,
            },
            "&::-webkit-scrollbar-track": {
              w: "6",
              // bg: `yellow.100`,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10",
              // bg: `yellow.100`,
            },
          }}
        >
          {title === "Requests" ? userList.map((user, index) => (
            <ListItemBox image={user?.profileImageUrl} key={index} text={user.userName} isOnline="N" 
            icon={<IoMdPersonAdd color={colors.primary}/>}
            onClick={() => handleAcceptConnection(user)}
            onTextClick={() => handleShowProfile(user)}/>
          )) : null}

          {title === "My Friends" ? userList.map((user, index) => (
            <ListItemBox image={user?.profileImageUrl} key={index} text={user.userName} isOnline="N" 
            onTextClick={() => handleChatBox(user)}
           onClick={() => handleChatBox(user)} />
          )) : null}
          

          {title === "My Clusters" ? userList.map((user, index) => (
            <ListItemBox image={user?.profileImageUrl} key={index} text={user.clusterName} isOnline="N" 
            onTextClick={() => handleOpenCluster(user)}
           onClick={() => handleOpenCluster(user)} icon={ <IoOpenOutline color={colors.primary}  />} />
          )) : null}

          {/* Your other hard-coded ListItemBox components go here */}
        </VStack>
      </Box>
    </Center>
  );
}
